var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"employment-table--content cursor-pointer"},[_c('div',{staticClass:"j-week--labels-container"},[_c('div',{staticClass:"journal--bar-dates mrg-t-10"},[_c('div',{staticClass:"left",on:{"click":_vm.prevWeek}},[_c('f-awesome',{attrs:{"icon":"arrow-left"}})],1),_c('div',{staticClass:"date"},[_c('b',[_vm._v(_vm._s(_vm.weekString))])]),_c('div',{staticClass:"right",on:{"click":_vm.nextWeek}},[_c('f-awesome',{attrs:{"icon":"arrow-right"}})],1)]),_c('div',{staticClass:"j-week--labels-overflow mrg-t-20"},_vm._l((_vm.calendar),function(item,index){return _c('div',{key:index,staticClass:"day"},[_c('div',{staticClass:"date text-center",class:{'today': item.isToday === true}},[_vm._v(" "+_vm._s(_vm.datePhpToRus(item.date))+", "+_vm._s(item.weekday)+" ")]),(Object.keys(item.labels).length === 0)?_c('div',{staticClass:"no-schedule"},[_vm._v(" Нет расписания ")]):_vm._e(),_c('div',{staticClass:"staffs"},_vm._l((item.labels),function(staff,index){return _c('div',{key:index,staticClass:"staff"},[(staff.result == 'error')?_c('div',{staticClass:"error"},[_vm._v(_vm._s(staff.text))]):_vm._e(),(staff.result == 'ok')?_c('div',{staticClass:"schedule"},_vm._l((staff.response),function(sch,index){return _c('div',{key:index,staticClass:"schedule-item"},[(sch.type == 'free')?_c('div',{staticClass:"item-free",class:{ progress: sch.progress === true }},[_c('div',[_c('span',[_vm._v("Окно ")]),_c('span',[_vm._v(_vm._s(_vm.minuteToTime(sch.time_from))+" - "+_vm._s(_vm.minuteToTime(sch.time_to)))]),_vm._v(" "),_c('br'),_c('span',{staticClass:"time-window"},[_vm._v("("+_vm._s(_vm.getDiffTime(sch.time_from, sch.time_to))+")")])]),_c('div',{staticClass:"action"})]):_vm._e(),(sch.type == 'enroll')?_c('div',{staticClass:"item-enroll",class:{
                    'status-new': sch.confirm == 0, 
                    'status-confirm': sch.confirm == 1, 
                    'status-done': sch.confirm == 2,
                    'status-canceled': sch.confirm == 3,
                    'progress': sch.progress === true
                  }},[_c('div',{staticClass:"time"},[_c('span',[_c('b',[_vm._v(_vm._s(_vm.minuteToTime(sch.time_from))+" - "+_vm._s(_vm.minuteToTime(sch.time_to)))])]),_vm._v(" "),_c('br'),_c('span',{staticClass:"time-window"},[_vm._v("("+_vm._s(_vm.getDiffTime(sch.time_from, sch.time_to))+")")])]),_c('div',{staticClass:"more mrg-t-10"},[(+sch.is_group === 0)?_c('div',{staticClass:"client_name"},[_vm._v(" "+_vm._s(sch.enrollClients[0].clients.name)+" "+_vm._s(sch.enrollClients[0].clients.surname)+" "),(sch.enrollClients[0].clients.sale)?_c('span',[_vm._v("(скидка "+_vm._s(sch.enrollClients[0].clients.sale)+"%) ")]):_vm._e(),(+sch.enrollClients[0].clients.is_unwanted === 1)?_c('span',[_c('b',[_vm._v("(нежелательный клиент!)")])]):_vm._e()]):_vm._e(),(+sch.is_group === 1)?_c('div',{staticClass:"client_name"},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.calculateActiveGroupCount(sch.enrollClients)))]),_vm._v(" клиента (-ов) в групповой записи ")]):_vm._e(),(sch.enrollPosition && sch.enrollPosition[0].service_name)?_c('div',{staticClass:"client_services mrg-t-10"},_vm._l((sch.enrollPosition),function(service){return _c('span',{key:service.id},[_c('b',[_vm._v(_vm._s(service.service_name)+";")])])}),0):_vm._e(),(sch.comments && sch.comments.comment_company)?_c('div',{staticClass:"company_comment mrg-t-5"},[_vm._v(" Комментарий компании: "+_vm._s(sch.comments?sch.comments.comment_company:'-')+" ")]):_vm._e()])]):_vm._e()])}),0):_vm._e()])}),0)])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }