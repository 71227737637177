<template>
  <div v-if="+computedPrepay === 1" class="notif-box mrg-t-10 shadow-card">
    <div class="text">
      <b>Внимание! Перед посещением вас могут попросить внести предоплату</b>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";


export default {
  name: "PrepayBlock",
  components: {},
  data() {
    return {

    };
  },
  props: {

  },
  watch: {

  },
  computed: {
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
    computedPrepay(){
      let result = 0;
      if(this.all_configs.show_prepay){
        result = this.all_configs.show_prepay;
      }
      return result;
    }
  },
  methods: {

  },
  created() {

  }
};
</script>

<style scoped lang="less"></style>
