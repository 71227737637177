import { render, staticRenderFns } from "./JournalMonth.vue?vue&type=template&id=8066d670&scoped=true"
import script from "./JournalMonth.vue?vue&type=script&lang=js"
export * from "./JournalMonth.vue?vue&type=script&lang=js"
import style0 from "./JournalMonth.vue?vue&type=style&index=0&id=8066d670&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8066d670",
  null
  
)

export default component.exports