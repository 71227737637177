import Vue from 'vue'
import VueRouter from 'vue-router'
import Company from '../views/Company.vue'
import Common from "@/views/Common";
import NotFound from "@/views/NotFound";
import Home from "@/views/Home";
import SearchCompany from "@/views/SearchCompany";
import Staff from "@/views/Staff";
import Unsubscribe from "@/views/Unsubscribe";
import Enroll from "@/views/Enroll";

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/search',
    name: 'Search',
    component: SearchCompany,
  },
      // Страница компании
  {
    path: '/c/:company',
    name: 'Company',
    component: Company,
  },
      // Общая страница, где отображаются все компании
  {
    path: '/m/:user',
    name: 'Common',
    component: Common,
  },
    // Страница записи клиента
    {
      path: '/enr/:server/:hash',
      name: 'Enroll',
      component: Enroll,
    },
    {
      path: '/staff/:companyId/:hash',
      name: 'Staff',
      component: Staff,
    },
    {
      path: '/unsubscribe/:hash',
      name: 'Unsubscribe',
      component: Unsubscribe
    },
  {
    path: "/*",
    name: "NotFound",
    component: NotFound,
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
