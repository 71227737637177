<template>
  <div class="mode-journal--content no-select">
    <div class="mode-journal--block">
      <div
        @click="selectMode(item.val)"
        v-bind:class="{ active: item.val === modeActive }"
        class="item"
        v-for="(item, index) in modeList"
        :key="index"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "ModeJournal",
  components: {},
  data() {
    return {};
  },
  props: {
    modeList: Array,
    modeActive: String
  },
  watch: {},
  computed: {},
  methods: {
    selectMode(val) {
      this.$emit("selectJournalMode", val);
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
.mode-journal--block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;
  color: #4f4f4f;
  font-size: 17px;

  & > .item {
    cursor: pointer;
  }
  & > .item.active {
    border-bottom: 2px solid #f97979;
    font-weight: 700;
    padding-bottom: 3px;
  }
}
</style>
