<template>
  <div class="enroll-page-info--container">
    <div class="enroll-page-info--staff">
      <div class="img">
        <img :src="enrollData.staff_avatar">
      </div>
      <div class="name text-orange">{{enrollData.staff_name}}</div>
      <div class="profession">{{enrollData.staff_profession}}</div>
    </div>
    <div class="enroll-page-info--date mrg-t-25">
      {{enrollData.date}}, {{enrollData.time}}
    </div>
    <div class="enroll-page-info--service mrg-t-25">
      <div class="table--container">
        <div class="table--header">
          <div class="name">Услуга</div>
          <div class="price">Стоимость</div>
        </div>
        <div class="table--rows">
          <div class="table--row" v-for="item in enrollData.enroll_position" :key="item.id">
            <div class="name">{{item.service_name}}</div>
            <div class="price">
              <span v-if="item.price == 0">-</span>
              <span v-if="item.price > 0">{{item.price}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="enrollData.all_price > 0" class="enroll-page-info--text mrg-t-25">
      Цены указаны на основе прайс-листа. Окончательная стоимость услуг определяется на месте
    </div>
  </div>
</template>

<script>

export default {
  name: "EnrollPageInfo",
  components: {},
  data() {
    return {

    };
  },
  props: {
    enrollData: Object
  },
  watch: {

  },
  computed: {},
  methods: {

  },
  created() {

  }
};
</script>

<style scoped lang="less">
  .enroll-page-info--container{
    padding: 25px 0 25px 0;
    background-color: #fff;

    .enroll-page-info--staff{
      text-align: center;

      img{
        width: 100px;
      }
      .name{
        font-weight: 700;
        margin-top: 5px;
        font-size: 15px;
      }
      .profession{
        font-size: 13px;
        color: gray;
      }
    }

    .enroll-page-info--date{
      font-size: 18px;
      font-weight: 700;
      text-align: center;
    }

    .enroll-page-info--service{

      .table--container{
        margin-top: 10px;
        overflow: auto;

        .table--header{
          display: flex;
          flex-wrap: nowrap;
          justify-content: center;
          flex-direction: row;
          font-size: 12px;
          color: gray;
          font-weight: 400;

          & > div {
            padding: 8px 0;
            box-sizing: border-box;
            border-bottom: 1px solid;
            border-color: rgba(212, 212, 212, 0.8);
          }

          .name{
            width: 70%;
          }
          .price{
            width: 20%;
          }
        }

        .table--rows{
          margin-top: 10px;

          .table--row{
            display: flex;
            flex-wrap: nowrap;
            justify-content: center;
            flex-direction: row;
            font-size: 15px;
            font-weight: 700;

            & > div {
              padding: 8px 0px;
              box-sizing: border-box;
              border-bottom: 1px solid;
              border-color: rgba(212, 212, 212, 0.8);
            }

            .name{
              width: 70%;
            }
            .price{
              width: 20%;
            }
          }
        }
      }
    }

    .enroll-page-info--text{
      text-align: center;
      padding: 10px 20px;
      box-sizing: border-box;
      color: gray;
      font-size: 12px;
    }
  }
</style>
