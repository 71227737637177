<template>
  <div v-if="show" class="cookie-confirm">
    <div class="text">
      Продолжая пользоваться сайтом, вы даете свое согласие на использование файлов cookie и обработку
      персональных данных.
    </div>
    <div class="text">
      Ознакомится с политикой обработки персональных данных можно по
      <a class="text-link text-bold" href="https://enroller.ru/privacy-policy.html" target="_blank">ссылке</a>
    </div>
    <div class="actions mrg-t-10">
      <div @click="acceptPolicy" class="btn btn-sm btn-orange">Я согласен</div>
    </div>
  </div>
</template>

<script>


export default {
  name: "CookieConfirm",
  components: {},
  data() {
    return {
      show: false
    };
  },
  methods: {
    checkAndShow(){
      let cookie = localStorage.getItem('cookie_policy');
      if(!cookie){
        this.show = true;
      }
    },
    acceptPolicy(){
      localStorage.setItem('cookie_policy', true);
      this.show = false;
    }
  },
  created() {
    this.checkAndShow();
  }
};
</script>

<style scoped lang="less">
  .cookie-confirm{
    left: 0;
    right: 0;
    margin: auto;
    position: fixed;
    bottom: 0;
    background-color: rgba(47, 107, 159, 1);
    padding: 15px;
    box-sizing: border-box;
    text-align: center;
    z-index: 999999999;

    .text{
      color: #fff;
    }
  }
</style>
