const all_configs = {
  state: {
    configs: {}
  },
  mutations: {
    ADD_CONFIGS(state, configs) {
      state.configs = configs;
    },
  },
  actions: {
    allConfigsAdd({ commit }, configs) {
      commit("ADD_CONFIGS", configs);
    },
  }
};

export default all_configs;
