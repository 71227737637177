<template>
  <div class="home">
    <yandex-metrika></yandex-metrika>
  </div>
</template>

<script>


import YandexMetrika from "@/components/YandexMetrika";
export default {
  name: 'Home',
  data(){
    return {
      company_id: null
    }
  },
  props: {

  },
  computed: {

  },
  methods: {
    goToCompany(){
      this.company_id = localStorage.getItem('company_id');
      if(this.company_id){
        this.$router.push("/c/" + this.company_id);
      }else{
        this.$router.push("/search");
      }
    }
  },
  components: {
    YandexMetrika

  },
  created() {
    this.goToCompany();
  }
}
</script>

<style scoped lang="less">

</style>
