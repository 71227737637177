<template>
  <div class="search-company">
    <div class="search-company--content">
      <div class="text-1">
        Необходимо один раз ввести ID компании, после чего он будет сохранен и последующий вход будет автоматический
      </div>
      <div class="text-2">
        <span class="weight">ID</span> содержится в конце ссылки компании (выделено оранжевым), которая вам нужна (пример:
        https://online.enroller.ru/c/<span class="weight text-orange">jQu</span>)
      </div>
      <div class="form">
        <div class="input-block">
          <label>ID компании</label>
          <input type="text" v-model="companyModel">
        </div>
        <div class="input-block">
          <div @click="setCompany" class="btn btn-sm btn-success">Сохранить</div>
        </div>
      </div>
    </div>
    <yandex-metrika></yandex-metrika>
  </div>
</template>

<script>


import YandexMetrika from "@/components/YandexMetrika";
export default {
  name: 'SearchCompany',
  data(){
    return {
      companyModel: '',
      company_id: null
    }
  },
  props: {

  },
  computed: {

  },
  methods: {
    setCompany(){
      localStorage.setItem('company_id', this.companyModel);
      this.goToCompany();
    },
    goToCompany(){
      this.company_id = localStorage.getItem('company_id');
      if(this.company_id){
        this.$router.push("/c/" + this.company_id);
      }else{
        return false;
      }
    }
  },
  components: {
    YandexMetrika

  },
  created() {

  }
}
</script>

<style scoped lang="less">
.search-company--content {
  width: 500px;
  margin: 0 auto 60px auto;

  .text-1{
    margin-top: 10px;
    font-size: 16px;

  }
  .text-2{
    margin-top: 10px;
    font-size: 16px;
  }
  .form{
    margin-top: 20px;
  }
  .weight{
    font-weight: 700;
  }
}
@media (max-width: 700px) {
  .search-company--content {
    width: 98%;
  }
}
</style>
