class YandexMetrika {

  constructor() {
    (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
      m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
        // eslint-disable-next-line no-unexpected-multiline
    (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
  }

  setCounter(id){
    window.ym(id, "init", {
      clickmap:true,
      trackLinks:true,
      accurateTrackBounce:true,
      webvisor:true
    });
  }

  reachGoal(targetNumber, targetName){
    window.ym(targetNumber, 'reachGoal', targetName);
  }
}

export default YandexMetrika;
