<template>
  <div v-show="!blockShow" class="select-services--container shadow-card">
    <div class="select-services--title">Выбрать услугу:</div>
    <div class="select-services--list">
      <ul class="select-services--cat-ul">
        <li
            class="cursor-pointer select-services--cat-li"
            v-bind:class="{ active: cat.showService === true }"
            v-for="cat in services"
            :key="cat.id"
        >

          <div @click="slideCategory(cat.id)" class="select-services--cat-data">
            <div class="name">{{ cat.name }}</div>
            <div class="checked">
              <f-awesome v-if="!cat.showService" icon="plus-circle"></f-awesome>
              <f-awesome v-if="cat.showService" icon="minus-circle"></f-awesome>
            </div>
          </div>

          <ul class="select-services--service-data" v-if="cat.showService">
            <li
                v-bind:class="{ active: service.checked === true, available: service.available }"
                class="select-services--service-li"
                v-for="service in cat['services']"
                :key="service.id"
            >
              <div class="select-services--service-item" @click="checkService(service.id)" v-if="service.subcategory == 0">
                  <div class="info">
                    <div class="name">
                      <div class="select">
                        <f-awesome
                            v-if="service.checked"
                            icon="check-circle"
                        ></f-awesome>
                      </div>
                      <div v-bind:class="{active: service.checked === true}">{{ service.name }}</div>
                    </div>
                    <div class="description">{{ service.description }}</div>
                    <div class="price-and-duration">
                      <div class="price">
                        <span v-if="service.price > 0">
                          <span v-if="+service.price_from_flag === 1">От </span>
                          {{service.price}}
                          <span v-if="all_configs.currency == 0">&#8381;</span>
                          <span v-if="all_configs.currency == 1">&#8381;</span>
                          <span v-if="all_configs.currency == 2">&#8376;</span>
                          <span v-if="all_configs.currency == 3">сум</span>
                          <span v-if="all_configs.currency == 4">С</span>
                          <span v-if="all_configs.currency == 5">лей</span>
                          <span v-if="all_configs.currency == 6">СОМ</span>
                          <span v-if="all_configs.currency == 7">Zł</span>
                          <span v-if="all_configs.currency == 8">&#36;</span>
                          <span v-if="all_configs.currency == 9">&#8364;</span>
                        </span>
                        <span v-if="service.price == 0">&#8226;</span>
                      </div>
                      <div class="duration">Продолжительность: {{minuteToTime(service.time)}}</div>
                    </div>
                  </div>
              </div>

              <div
                  v-if="service.subcategory == 1"
                  class="select-services--subcat-data"
                  v-bind:class="{ active: service.showModifications === true, available: service.available }"
              >
                <div
                    v-if="service.subcategory == 1"
                    @click="service.showModifications = !service.showModifications"
                    class="select-services--cat-data"
                >
                  <div class="name">{{ service.name }}</div>
                  <div class="checked">
                    <f-awesome v-if="!service.showModifications" icon="plus-circle"></f-awesome>
                    <f-awesome v-if="service.showModifications" icon="minus-circle"></f-awesome>
                  </div>
                </div>

                <ul class="select-services--modification-data" v-if="service.showModifications">
                  <li
                      v-bind:class="{ active: service.showModifications === true, available: modif.available }"
                      class="select-services--modification-li"
                      v-for="modif in service['modification']"
                      :key="modif.id"
                  >
                    <div class="select-services--service-item" @click="checkModification(modif.id)">
                      <div class="info">
                        <div class="name">
                          <div class="select">
                            <f-awesome
                                v-if="modif.checked"
                                icon="check-circle"
                            ></f-awesome>
                          </div>
                          <div>{{ modif.name }}</div>
                        </div>
                        <div class="description">{{ modif.description }}</div>
                        <div class="price-and-duration">
                          <div class="price">
                            <span v-if="modif.price > 0">
                              <span v-if="+modif.price_from_flag === 1">От </span>
                              {{modif.price}}
                              <span v-if="all_configs.country_code == 7">Р</span>
                              <span v-if="all_configs.country_code == 375">Р</span>
                              <span v-if="all_configs.country_code == 380">грн</span>
                            </span>
                            <span v-if="modif.price == 0">&#8226;</span>
                          </div>
                          <div class="duration">Продолжительность: {{minuteToTime(modif.time)}}</div>
                        </div>
                      </div>

                    </div>
                  </li>
                </ul>

              </div>

            </li>
          </ul>

        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

import axios from "axios";

export default {
  name: "SelectServices",
  components: {},
  data() {
    return {
      blockShow: true, // Заблокировать отображение. Делаем во время автовыбора, чтобы не было скачка
      services: [],
      matrix: [],
      routeParams: this.$route.params,
    };
  },
  props: {
    selectReset: Number, // Сброс данных компонента
    componentReset: Number,
    staff: Object, // Выбранный сотрудник
    rerender: Boolean,
    rerenderForce: Number
  },
  watch: {
    rerender: function(val) {
      if (val === true && !this.services.length) {
        this.createdComponent();
      }
    },
    rerenderForce: function(){
      this.createdComponent();
    },
    componentReset: function() {
      this.resetComponent();
    },
    selectReset: function() {
      this.resetSelect();
    }
  },
  computed: {
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
  },
  methods: {
    getStaffId() {
      let result = false;
      if (this.staff) {
        result = this.staff.id;
      }
      return result;
    },
    getDuration(minute) {
      return formatter.minuteToTime(minute);
    },
    minuteToTime(minute){
      return formatter.minuteToTime(minute);
    },
    // Добавляет необходимые для рендерига параметры в полученных от сервера данных
    filteredGetData(data) {
      let newData = data;
      // Исключим из списка удаленные категории и услуги
      let result = newData.reduce((finalList, item) => {
        if(+item.deleted === 0){
          item.services = item.services.filter(serv => {
            return +serv.deleted !== 1;
          });
          // item.services = filterServ;
          finalList.push(item);
        }
        return finalList;
      }, []);

      // Если у нас одна категория, то будем разворачивать список, если несколько, то отображаем в свернутом виде
      let isOneCat = true;
      if(result.length > 1){
        isOneCat = false;
      }

      for (let key in result) {
        result[key].showService = isOneCat;
        for (let serv in result[key].services) {
          result[key].services[serv].service_id = result[key].services[serv].id;
          result[key].services[serv].modification_id = '0';
          result[key].services[serv].category_name = result[key].name;
          //result[key].services[serv].id = result[key].services[serv].id +'_' +'0';
          result[key].services[serv].checked = false;
          result[key].services[serv].available = true;
          result[key].services[serv].showModifications = true;
          // разобьем часы и минуты
          let smashHM = formatter.minuteSplitHourMinute(
            result[key].services[serv].time
          );
          result[key].services[serv].time_hour = smashHM.hour;
          result[key].services[serv].time_minute = smashHM.minute;
          for(let modif in result[key].services[serv].modification){
            result[key].services[serv].modification[modif].service_id = result[key].services[serv].id;
            result[key].services[serv].modification[modif].modification_id = result[key].services[serv].modification[modif].id;
            result[key].services[serv].modification[modif].category_name = result[key].name +' -> ' +result[key].services[serv].name;
            //result[key].services[serv].modification[modif].id = result[key].services[serv].id +'_' +result[key].services[serv].modification[modif].modification_id;
            result[key].services[serv].modification[modif].available = true;
            let smashHM = formatter.minuteSplitHourMinute(
                result[key].services[serv].modification[modif].time
            );
            result[key].services[serv].modification[modif].time_hour = smashHM.hour;
            result[key].services[serv].modification[modif].time_minute = smashHM.minute;
          }
        }
      }
      return result;
    },
    slideCategory(id) {
      for (let key in this.services) {
        if (+this.services[key].id === +id) {
          this.$set(
            this.services[key],
            "showService",
            !this.services[key].showService
          );
        }
      }
    },
    checkService(service) {
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          if(
            +this.services[key].services[serv].id === +service && 
            !this.services[key].services[serv].available
          ){
            let errMessage = "";
            if(this.all_configs.multiple_services == 1){
              errMessage = "Возможно выбрать только 1 услугу";
            }else if(this.all_configs.multiple_services == 0){
              errMessage = "Данная услуга недоступна вместе с выбранными, т.к нет сотрудника, который может оказать данный набор услуг. Вы можете выбрать ее, " +
                  "сделав вторую запись, сразу после текущей";
            }
            this.$store.dispatch("addNotification", {
              text: errMessage,
              time: 6,
              color: "danger"
            });
            return false;
          }
        }
      }
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          if (+this.services[key].services[serv].id === +service) {
            this.$set(
              this.services[key].services[serv],
              "checked",
              !this.services[key].services[serv].checked
            );
          }
        }
      }
      this.updateAvailable();
      this.emitList();
    },
    checkModification(modificationId){
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          for(let modif in this.services[key].services[serv].modification){
            if(
                +this.services[key].services[serv].modification[modif].id === +modificationId &&
                !this.services[key].services[serv].modification[modif].available
            ){
              let errMessage = "";
              if(this.all_configs.multiple_services == 1){
                errMessage = "Возможно выбрать только 1 услугу";
              }else if(this.all_configs.multiple_services == 0){
                errMessage = "Данная услуга недоступна вместе с выбранными, т.к нет сотрудника, который может оказать данный набор услуг. Вы можете выбрать ее, " +
                    "сделав вторую запись, сразу после текущей";
              }
              this.$store.dispatch("addNotification", {
                text: errMessage,
                time: 6,
                color: "danger"
              });
              return false;
            }
          }
        }
      }
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          for(let modif in this.services[key].services[serv].modification){
            if (+this.services[key].services[serv].modification[modif].id === +modificationId) {
              this.$set(
                  this.services[key].services[serv].modification[modif],
                  "checked",
                  !this.services[key].services[serv].modification[modif].checked
              );
            }
          }
        }
      }
      this.updateAvailable();
      this.emitList();
    },
    updateAvailable(){
      let existsChecked = false;
      let existsGroup = false;
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          if(this.services[key].services[serv].checked){
            existsChecked = true;
            if(+this.services[key].services[serv].is_group === 1){
              existsGroup = true;
            }
          }
          for (let modif in this.services[key].services[serv].modification){
            if(this.services[key].services[serv].modification[modif].checked){
              existsChecked = true;
              if(+this.services[key].services[serv].modification[modif].is_group === 1){
                existsGroup = true;
              }
            }
          }
        }
      }
      let checkedServs = [];
      let checkedModification = [];
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          if (this.services[key].services[serv].checked) {
            if (!checkedServs.includes(this.services[key].services[serv].id)) {
              checkedServs[checkedServs.length] = this.services[key].services[serv].id;
            }
          }
          for (let modif in this.services[key].services[serv].modification){
            if(this.services[key].services[serv].modification[modif].checked){
              if(!checkedModification.includes(this.services[key].services[serv].modification[modif].id)){
                checkedModification[checkedModification.length] = this.services[key].services[serv].modification[modif].id;
              }
            }
          }
        }
      }

      let multipleServices = this.all_configs.multiple_services;
      if(existsGroup){
        // Если выбрана групповая, то подменяем настройку, т.к для групповых 
        // всегда можно выбрать только 1 услугу
        multipleServices = 1;
      }

      // multiple_services == 1 - может выбрать только 1 услугу
      if(multipleServices == 1){
        for (let key in this.services) {
          for (let serv in this.services[key].services) {
            if(existsChecked){
              if(this.services[key].services[serv].checked){
                this.$set(this.services[key].services[serv], "available", true);
              }else{
                this.$set(this.services[key].services[serv], "available", false);
              }
            }else{
              this.$set(this.services[key].services[serv], "available", true);
            }

            for (let modif in this.services[key].services[serv].modification){
              if(existsChecked){
                if(this.services[key].services[serv].modification[modif].checked){
                  this.$set(this.services[key].services[serv].modification[modif], "available", true);
                }else{
                  this.$set(this.services[key].services[serv].modification[modif], "available", false);
                }
              }else{
                this.$set(this.services[key].services[serv].modification[modif], "available", true);
              }
            }
          }
        }
      }
      if(multipleServices == 0){
        let availableServByMatrix = []; //  активные услуги
        let availableModifByMatrix = []; // активные модификации
        // Сначала узнаем какие матрицы нам подходят под уже выбранные услуги
        for(let keyMatrix in this.matrix){
          let isYes = true;

          for(let checked in checkedServs){
            if(!this.matrix[keyMatrix].services.includes(checkedServs[checked])){
              isYes = false;
            }
          }

          for(let checked in checkedModification){
            if(!this.matrix[keyMatrix].modifications.includes(checkedModification[checked])){
              isYes = false;
            }
          }
          if(isYes){
            for (let servMatrx in this.matrix[keyMatrix].services){
              if (!availableServByMatrix.includes(this.matrix[keyMatrix].services[servMatrx])){
                availableServByMatrix[availableServByMatrix.length] = this.matrix[keyMatrix].services[servMatrx];
              }
            }
            for (let modifMatrx in this.matrix[keyMatrix].modifications){
              if (!availableModifByMatrix.includes(this.matrix[keyMatrix].modifications[modifMatrx])){
                availableModifByMatrix[availableModifByMatrix.length] = this.matrix[keyMatrix].modifications[modifMatrx];
              }
            }
          }
        }
        // На основе полученных данных будем делать услуги доступными или недоступными
        for (let key in this.services) {
          for (let serv in this.services[key].services) {
            if(
              existsChecked && !existsGroup && +this.services[key].services[serv].is_group === 1
            ){
              this.$set(
                  this.services[key].services[serv],
                  "available",
                  false
              );
            }else if(
              this.services[key].services[serv].checked || 
              availableServByMatrix.includes(this.services[key].services[serv].id)
            ){
              this.$set(
                  this.services[key].services[serv],
                  "available",
                  true
              );
            }else{
              this.$set(
                  this.services[key].services[serv],
                  "available",
                  false
              );
            }

            for (let modif in this.services[key].services[serv].modification){
              if(
                existsChecked && 
                !existsGroup && 
                +this.services[key].services[serv].modification[modif].is_group === 1
              ){
                this.$set(
                    this.services[key].services[serv].modification[modif],
                    "available",
                    false
                );
              }else if(
                  this.services[key].services[serv].modification[modif].checked ||
                  availableModifByMatrix.includes(this.services[key].services[serv].modification[modif].id)
              ){
                this.$set(
                    this.services[key].services[serv].modification[modif],
                    "available",
                    true
                );
              }else{
                this.$set(
                    this.services[key].services[serv].modification[modif],
                    "available",
                    false
                );
              }
            }
          }
        }

      }
    },
    resetSelect() {
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          this.$set(this.services[key].services[serv], "checked", false);
          for (let modif in this.services[key].services[serv].modification){
            this.$set(this.services[key].services[serv].modification[modif], "checked", false);
          }
        }
      }
      this.updateAvailable();
      this.emitList();
    },
    resetComponent() {
      this.services = [];
      this.emitList();
    },
    emitList() {
      let result = [];
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          if (this.services[key].services[serv].checked) {
            result.push(this.services[key].services[serv]);
          }
          for (let modif in this.services[key].services[serv].modification){
            if(this.services[key].services[serv].modification[modif].checked){
              result.push(this.services[key].services[serv].modification[modif]);
            }
          }
        }
      }
      this.$emit("selectServices", result);
    },
    emitListAndNext() {
      let result = [];
      for (let key in this.services) {
        for (let serv in this.services[key].services) {
          if (this.services[key].services[serv].checked) {
            result.push(this.services[key].services[serv]);
          }
          for (let modif in this.services[key].services[serv].modification){
            if(this.services[key].services[serv].modification[modif].checked){
              result.push(this.services[key].services[serv].modification[modif]);
            }
          }
        }
      }
      this.$emit("selectServicesAndNext", result);
    },
    checkForOneService(){
      this.blockShow = true;
      let countServices = 0;
      for(let key in this.services){
        for(let keyServices in this.services[key].services){
          if(+this.services[key].services[keyServices].subcategory === 1){
            countServices += this.services[key].services[keyServices].modification.length;
          }else{
            countServices++;
          }
        }
      }
      if(countServices === 1){
        for(let key in this.services){
          for(let keyServices in this.services[key].services){
            if(+this.services[key].services[keyServices].subcategory === 1){
              for(let keyModif in this.services[key].services[keyServices].modification){
                this.checkModification(this.services[key].services[keyServices].modification[keyModif].id);
                break;
              }
            }else{
              this.checkService(this.services[key].services[keyServices].id);
              break;
            }
          }
        }
        this.emitListAndNext();
      }

      this.blockShow = false;
    },
    createdComponent() {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      let staffId = this.getStaffId();
      if (staffId) {
        formData.append("id", staffId);
      }
      formData.append("company", this.routeParams.company);
      axios
        .post(`/online/get-services`, formData)
        .then(resp => {
          if(!resp.data.services.length){
            this.$emit('setDiagnostic', 'Похоже, что не найдено ни одной услуги. Сделайте запись по номеру телефона');
          }
          this.services = this.filteredGetData(resp.data.services);
          if(+this.all_configs.autoselect_one_service === 1){
            this.checkForOneService();
          }else{
            this.blockShow = false;
          }
          this.matrix = resp.data.matrix;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
.select-services--container{
  margin-top: 20px;
  padding: 15px 0;
}

.select-services--title {
  text-align: center;
  color: #4f4f4f;
  font-size: 16px;
  margin-bottom: 30px;
}
.select-services--cat-ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.select-services--cat-li{
  padding: 0 15px;
  border-left: 2px solid transparent;
  background-color: #ffffff;
  margin-top: 10px;

  &.active{
    border-left: 2px solid #f97979;
  }
}

.select-services--cat-data {
  list-style-type: none;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  padding: 20px 0;

  .name {
    width: 60%;
    color: #333333;
    font-size: 16px;
    font-weight: 700;
  }
  .checked {
    text-align: center;
    width: 30%;
    color: #f97979;

    svg {
      font-size: 18px;
    }
  }
}
.select-services--service-data{
  margin: 0;
  padding: 0 0 0 15px;
  list-style-type: none;
}
.select-services--service-li:not(.available){
  &>.select-services--service-item{
    .price{
      color: #d2d2d2 !important;
    }
    .name{
      color: #d2d2d2 !important;
    }
    .description{
      color: #d2d2d2 !important;
    }
  }
}
.select-services--service-item{
  padding: 10px 0;
  //margin-top: 8px;
  border-bottom: 1px solid #E2E7ED;

  &>.info{
    width: 95%;

    .name{
      &>div:nth-of-type(1){
        display: inline;
        color: #f97979;

        &>svg{
          margin-right: 7px;
        }

        &.active{
          font-weight: 700;
        }
      }
      &>div:nth-of-type(2){
        display: inline;
        font-size: 16px;

        &.active{
          font-weight: 700;
        }
      }
    }

    .description{
      margin-top: 4px;
      font-size: 12px;
      color: #828282;
    }
    .price-and-duration{
      margin-top: 4px;

      .price{
        display: inline-block;
        color: #F97979;
        font-size: 16px;
        font-weight: 700;
      }
      .duration{
        display: inline-block;
        margin-left: 10px;
        font-size: 12px;
        color: #828282;
      }
    }
  }
}
.select-services--subcat-data{
  margin-bottom: 10px;
}

.select-services--modification-li:not(.available){
  &>.select-services--service-item{
    .price{
      color: #d2d2d2 !important;
    }
    .name{
      color: #d2d2d2 !important;
    }
    .description{
      color: #d2d2d2 !important;
    }
  }
}
.select-services--modification-li{
  border-left: 1px solid transparent;
  background-color: #ffffff;
  padding-left: 15px;

  &.active{
    border-left: 1px solid #f97979;
  }
}
.select-services--modification-data{
  margin: 0;
  padding: 0 0 0 17px;
  list-style-type: none;
}
@media (max-width: 480px) {
  .select-services--cat-data {

    .name {
      width: 70%;
      font-size: 15px;
    }
    .checked {
      width: 20%;
    }
  }

  .select-services--service-item{
    &>.info{
      width: 95%;

      .name{
        &>div:nth-of-type(2){
          font-size: 15px;
        }
      }

      .description{
        font-size: 12px;
      }
      .price-and-duration{
        .price{
          font-size: 15px;
        }
        .duration{
          font-size: 11px;
        }
      }
    }
  }
}
</style>
