<template>
  <div class="select-interval--container no-select shadow-card">
    <div v-if="intervals.length" class="select-calendar--title">
      Выбрать время:
    </div>
    <div class="select-interval--content">
      <div
        v-bind:class="{ active: selectTime === item.from }"
        @click="selectInterval(item.from)"
        v-for="(item, key) in intervals"
        :key="key"
      >
        {{ minuteToTime(item.from) }}
      </div>
    </div>
  </div>
</template>

<script>
import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

// import axios from "axios";

//import axios from "axios";

export default {
  name: "SelectInterval",
  components: {},
  data() {
    return {
      selectTime: ""
    };
  },
  props: {
    intervals: Array,
    reset: Number
  },
  watch: {
    reset: function() {
      this.resetSelect();
    },
    intervals: function() {
      this.resetSelect();
    }
  },
  computed: {},
  methods: {
    selectInterval(time) {
      this.selectTime = time;
      this.$emit("selectTime", time);
    },
    resetSelect() {
      this.selectTime = "";
    },
    minuteToTime(minute) {
      return formatter.minuteToTime(minute);
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
.select-interval--container{
  padding: 15px 0;
  margin-top: 20px;
}
.select-interval--content {
  padding: 15px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  flex-direction: row;

  & > div {
    cursor: pointer;
    width: 40px;
    font-size: 15px;
    line-height: 16px;
    color: #333333;
    text-align: center;
    margin: 3px 5px;
    padding: 5px;
    border: 1px solid transparent;
    /*box-sizing: border-box; */
    /*border: 1px solid red;*/
  }
  & > div.active {
    background-color: #efefef;
    color: #f97979;
    font-weight: 700;
    //border: 1px solid #f97979;
  }
}
.select-calendar--title {
  text-align: center;
  color: #4f4f4f;
  font-size: 16px;
  margin-bottom: 30px;
}
</style>
