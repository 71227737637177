<template>
  <div class="head-staff-card--container">
    <div class="img">
      <img :src="img">
    </div>
    <div class="text">
      <div class="name">{{name}}</div>
      <div class="profession">{{profession}}</div>
      <div v-if="link" class="link">
        <a :class="{active: linkStatus == 1, not_active: linkStatus == 0}" :href="link" target="_blank">{{link}}</a>
      </div>
    </div>
  </div>
</template>

<script>


export default {
  name: "HeadStaffCard",
  components: {},
  data() {
    return {

    };
  },
  props: {
    img: String,
    name: String,
    profession: String,
    link: String,
    linkStatus: Number
  },
  watch: {

  },
  computed: {

  },
  methods: {

  }
};
</script>

<style scoped lang="less">
.head-staff-card--container{
  text-align: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 6px;
  box-sizing: border-box;
  box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.25);

  .img{
    width: 100%;

    img{
      width: 80px;
    }
  }
  .text{
    width: 100%;

    .name{
      color: #41A2FC;
      font-size: 14px;
      font-weight: 500;
      margin-top: 6px;
    }
    .profession{
      color: #7FA1CA;
      font-size: 13px;
      margin-top: 6px;
    }
    .link{
      font-size: 11px;
      margin-top: 10px;
      text-decoration: underline;

      .active{
        color: #41A2FC;
      }
      .not_active{
        color: #b8bcbc;
      }
    }
  }
}
</style>
