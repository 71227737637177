<template>
  <div class="company-info--container shadow-card">
    <div class="company-info--block">
      <div class="img">
        <img :src="data.image">
      </div>
      <div class="info">
        <div class="name">{{data.name}}</div>
        <div class="city mrg-t-10">{{data.city}}</div>
        <div class="address">
          <div v-if="!data.lattitude || data.lattitude === ''">
            {{data.address}}
          </div>
          <div v-if="data.lattitude && data.lattitude.length > 0">
            <a 
            class="text-link"
              :href="'https://yandex.ru/maps/?pt=' +data.longtitude +',' +data.lattitude +'&z=18&l=map'"
              target="_blank"
            >
              <span>{{data.address}} </span>
              <span class="text-orange"><f-awesome icon="map-marker-alt"></f-awesome></span>
            </a>
          </div>
        </div>
        <div v-if="all_configs.hide_phone_number == 0" class="number mrg-t-10">{{data.number}}</div>
      </div>
    </div>
  </div>
</template>

<script>
// import Formatter from "../../libraries/Formatter";
//
// const formatter = new Formatter();

// import axios from "axios";

import axios from "axios";
import { mapState } from "vuex";

export default {
  name: "CompanyInfo",
  components: {},
  data() {
    return {
      data: {
        name: '',
        address: '',
        city: '',
        image: '',
        number: ''
      },
      routeParams: this.$route.params,
    };
  },
  props: {
    stopLoad: Boolean, // Останавливает автоматическую загрузку компании
    setData: Object
  },
  watch: {
    setData: function(val){
      this.data.name = val.name;
      this.data.address = val.address;
      this.data.city = val.city;
      this.data.image = val.image;
      this.data.number = val.number;
    }
  },
  computed: {
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
  },
  methods: {
    getCompanyData(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("company", this.routeParams.company);
      axios
          .post(`/online/get-company-data`, formData)
          .then(resp => {
            this.data = resp.data;
            this.$emit('setCompanyNumber', this.data.number);
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
            this.$store.dispatch("stopPreloader");
          });
    }
  },
  created() {
    if(!this.stopLoad){
      this.getCompanyData();
    }
  }
};
</script>

<style scoped lang="less">
  .company-info--block{
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: row;
    padding: 5px 0 15px 0;

    &>div:nth-of-type(1){
      width: 35%;
      text-align: center;
      //border: 1px solid red;
    }
    &>div:nth-of-type(2){
      width: 65%;
      //border: 1px solid red;
    }

    .info{
      padding: 8px;
      box-sizing: border-box;
      color: #4F4F4F;

      .name{
        font-size: 16px;
        font-weight: 700;
      }
      .address{
        font-size: 14px;
      }
    }

    .img{
      position: relative;
      img{
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 85px;
      }
    }
  }
</style>
