const yametric_target = {
  state: {
    targets: [],
  },
  mutations: {
    ADD_TARGET(state, target) {
      state.targets.push(target);
    },
    REMOVE(state) {
      state.targets = [];
    }
  },
  actions: {
    /**
     * @param commit
     * @param target - {targetName: 'TARGET_NAME'}
     */
    addYaMetricTarget({ commit }, target) {
      commit("ADD_TARGET", target);
    },
    flushYaMetricTargets({ commit }) {
      commit("REMOVE");
    }
  }
};

export default yametric_target;
