<template>
  <div class="rules-online-enroll--container">
    <div style="font-style: italic">
      <p>
        Настоящие Правила разработаны Владельцем Сервиса онлайн-записи Enroller
        Индивидуальным предпринимателем Вялых Александром Александровичем (далее – Владелец Сервиса) в целях
        определения условий доступа граждан к интерфейсу Сервиса и его использования
        при заполнении и отправке соответствующей формы онлайн-записи, а также информирования
        о процедуре онлайн-записи через Сервис. Положения настоящих Правил адресуются всем лицам,
        совершающим действия по направлению заявки на получение определенной услуги через функционал Сервиса.
      </p>
      <p>
        Нажимая кнопку «Записаться», Вы выражаете согласие со всеми нижеприведенными правилами
        без каких-либо исключений и принимаете на себя все риски, вытекающие из пренебрежения
        Вашей обязанностью ознакомиться с настоящим документом. Если Вы не согласны с условиями,
        указанными ниже, пожалуйста, воздержитесь от заполнения формы онлайн-записи.
      </p>
    </div>
    <div class="mrg-t-15">
      <p>
        1. Сервис онлайн-записи Enroller (далее – Сервис) представляет собой программу для ЭВМ,
        предназначенную для автоматизации и учета записей граждан на получение услуг
        зарегистрированных в Сервисе индивидуальных предпринимателей, юридических лиц и частных
        специалистов (далее – Исполнитель). Условия для оформления и учета в Сервисе
        соответствующих заявок обеспечиваются Владельцем Сервиса во исполнение своих
        обязательств, принятых в рамках Пользовательского соглашения, заключенного с
        конкретным Исполнителем. С содержанием этого соглашения Вы можете ознакомиться по ссылке:
        <b><a class="text-link" href="https://enroller.ru/user-agreement.html" target="_blank">https://enroller.ru/user-agreement.html</a></b>
      </p>
      <p>
        2. Владелец Сервиса предоставляет лишь возможность размещения информации,
        необходимой для автоматизации и учета Вашей записи на получение услуги Исполнителя,
        возможность передачи такой информации, а также доступа к размещенной в Сервисе
        информации об Исполнителе и его услугах. При этом Владелец Сервиса не инициирует
        передачу через Сервис определенной информации, не определяет получателей этой информации,
        не изменяет информацию, размещенную в Сервисе.
      </p>
      <p>
        3. Владелец Сервиса не оказывает услуги связи, не организует доступ к
        информационно-телекоммуникационным сетям, в том числе, к сети «Интернет», не
        осуществляет деятельность по приему, обработке, хранению, передаче и доставке
        сообщений электросвязи.
      </p>
      <p>
        4. Информация об Исполнителе, его контактные данные, список услуг и сотрудников,
        расписание работы и оказания услуг размещается в Сервисе самим Исполнителем без
        предварительной модерации со стороны Владельца Сервиса, который не проверяет достоверность,
        актуальность и корректность информации, сообщенной Исполнителем, а также факт соответствия
        предоставленных сведений законодательству, отсутствия нарушений прав, свобод и
        законных интересов третьих лиц.
      </p>
      <p>
        5. Исполнитель также без участия Владельца Сервиса самостоятельно производит настройку
        содержания страницы онлайн-записи, по своему усмотрению, но в соответствии с
        ограничениями и запретами, установленными Пользовательским соглашением, определяет
        объем Ваших данных, которые будут запрашиваться у Вас при заполнении заявки онлайн-записи.
      </p>
      <p>
        6. При использовании Сервиса Исполнитель также может настроить систему
        уведомлений и электронных рассылок, которые будут направляться Вам в связи с
        произведенной записью. Ответственность за все действия по направлению Вам подобного
        рода уведомлений и получению Вашего согласия возлагается на Исполнителя.
      </p>
      <p>
        7. Оказывая услуги по обеспечению автоматизации онлайн-записи и учета онлайн-записей,
        Владелец Сервиса не участвует в каких-либо ни было формах в расчетах между Вами и
        Исполнителем, не принимает оплату (предоплату) услуг Исполнителя.
      </p>
      <p>
        8. Владелец Сервиса не является участником обязательственных отношений,
        возникающих между Вами и Исполнителем, не несет ответственность перед Вами за
        действия (бездействие) Исполнителя, своевременность, качество и надлежащий характер
        оказания Вам Исполнителем своих услуг, соблюдение Исполнителем законодательства о
        защите прав потребителей. Все требования, вытекающие из отношений в связи с оказанием
        услуг Исполнителем, адресуются Исполнителю напрямую и не решаются через посредничество
        Владельца Сервиса.
      </p>
      <p>
        9. Владелец Сервиса не несет ответственность перед Вами по любым обязательствам,
        связанным с реализацией товаров и услуг Исполнителя, не гарантирует достоверность
        любой информации, размещаемой в Сервисе.
      </p>
      <p>
        10. Размещение в Сервисе информации об Исполнителе и его услугах, сотрудниках ни при
        каких условиях не может считаться рекламой, одобрением таких услуг со стороны Владельца
        Сервиса, гарантией качества услуг либо соответствия этих услуг требованиям закона и
        Вашим ожиданиям.
      </p>
      <p>
        11. В целях исполнения вытекающих из Пользовательского соглашения обязательств Владелец
        Сервиса ведет обработку Ваших данных, в том числе, персональных данных, по поручению
        Исполнителя и в пределах, определяемых самим Исполнителем. Исполнитель самостоятельно
        определяет перечень действий с Вашими персональными данными, которые собираются через
        форму онлайн-записи, в пределах технических возможностей Сервиса. При обработке
        персональных данных Владелец Сервиса руководствуется положениями утвержденной Политики
        в отношении обработки персональных данных, опубликованной по ссылке
        <b><a class="text-link" href="https://enroller.ru/privacy-policy.html" target="_blank">https://enroller.ru/privacy-policy.html</a></b>
      </p>
      <p>
        12. Нажимая кнопку «Записаться» в онлайн-форме записи Вы одномоментно также даете согласие
        на обработку Ваших персональных данных на условиях, определенных в Политике в отношении
        обработки персональных данных.
      </p>
      <p>
        13. Владелец Сервиса не отвечает за действия Исполнителя по обработке Ваших персональных
        данных через Сервис, за любые нарушения прав субъектов персональных данных со стороны
        Исполнителя, за достаточность и качество предпринимаемых Исполнителем мер по защите
        Ваших персональных данных, за сохранение их конфиденциальности при использовании Сервиса.
        При этом Владелец Сервиса гарантирует, что в своей части соблюдает требования
        законодательства и режим конфиденциальности в отношении Ваших данных, в том числе,
        персональных данных. Конкретный перечень принимаемых мер определяется Политикой в
        отношении обработки персональных данных Владельца Сервиса и документами Исполнителя.
      </p>
      <p>
        14. Владелец Сервиса не отвечает за количество, содержание и достоверность направляемой
        Вами через Сервис информации, а также за любые Ваши действия (бездействия) в связи с
        записью к Исполнителю и потреблением его услуг. При заполнении формы онлайн-записи Вы
        обязуетесь предоставлять достоверные сведения и не вправе заполнять форму онлайн-записи
        на имя других лиц. Ответственность за нарушение данного требования возлагается на Вас
        в полном объеме.
      </p>
      <p>
        15. Владелец Сервиса не несет ответственность за возможные последствия его использования
        Вами и Исполнителем. Владелец Сервиса не несет ответственность за любые убытки и
        ущерб, потерю информации, иные последствия, произошедшие вследствие использования либо
        неиспользования Сервиса в целом либо его отдельных функций, в том числе, из-за возможных
        ошибок и сбоев в работе.
      </p>
      <p>
        16. В связи с тем, что Сервис постоянно обновляется и дорабатывается, Владелец Сервиса
        не гарантирует его бесперебойного и  безошибочного функционирования. Владелец Сервиса
        не отвечает за любой ущерб Вашим устройствам, оборудованию или программному обеспечению,
        вызванный использованием Сервиса.
      </p>
      <p>
        17. Владелец Сервиса не проверяет и не имеет технической и фактической возможности проверки
        информации, которую Исполнитель либо Вы размещаете в Сервисе, на предмет ее соответствия
        требованиям закона и условиям настоящих Правил. Ответственность за содержание, достоверность,
        полноту и соответствие законодательству, права и свободам третьих лиц всей размещаемой
        информации в Сервисе либо посредством Сервиса несет лицо, разместившее такую информацию.
      </p>
      <p>
        18. Владелец Сервиса не отвечает за возможную потерю или искажение данных, а также иные
        подобные последствия, которые могут произойти не по вине Владельца Сервиса, а также в
        случае нарушения Исполнителем либо Вами правил конфиденциальности и безопасной работы
        с Сервисом.
      </p>
      <p>
        19. Доступ к Сервису предоставляется по принципу «as is», без каких-либо явных или
        подразумеваемых гарантий, в качестве и объеме и с учетом функциональных возможностей
        Сервиса. Владелец Сервиса не гарантирует достижение посредством использования Сервиса
        какого-либо определенного результата, пригодность Сервиса для Ваших целей, а также
        совместимость Сервиса с Вашим программным обеспечением и аппаратными средствами.
      </p>
      <p>
        20. Владелец Сервиса не гарантирует, что Сервис будет соответствовать Вашим ожиданиям и
        требованиям, будет работать непрерывно, быстро и без ошибок, а результаты, полученные с
        использованием Сервиса, будут надежными и точными и могут использоваться для установления
        либо подтверждения каких-либо фактов. Владелец Сервиса не гарантирует качество товара,
        продукта либо услуги (работы), информации, полученных посредством использования Сервиса.
      </p>
      <p>
        21. Производя онлайн-запись через Сервис, Вы обязуетесь не использовать Сервис любыми
        способами, нарушающими интеллектуальные и иные права Правообладателя и третьих лиц;
        не размещать в Сервисе и не распространять посредством него запрещенную к распространению
        информацию, информацию, распространение которой ограничивается в соответствии с российским
        законодательством; информацию, нарушающую права и законные интересы третьих лиц,
        недостоверную и вводящую в заблуждение информацию; непристойные материалы и материалы с
        использованием нецензурной лексики.
      </p>
      <p>
        22. Вы не вправе использовать Сервис для совершения правонарушений, преступлений и иной
        противоправной деятельности, для осуществления деятельности, противоречащей нормам
        морали и общественной нравственности. Ни при каких условиях Вы не имеете права совершать
        действия, нарушающие нормальное функционирование Сервиса и его отдельных функций;
        загружать, хранить, публиковать, распространять и иным образом использовать при работе с
        Сервисом либо через него вредоносные компьютерные программы.
      </p>
      <p>
        23. Вы обязуетесь соблюдать положения законодательства и настоящие Правила, а также
        адресуемые Вам требования и рекомендации Владельца Сервиса.
      </p>
      <p>
        24. Настоящие правила могут быть изменены Владельцем Сервиса без предварительного
        согласования с Вами в одностороннем порядке.
      </p>
      <p>
        25. Претензии, мотивированные отказы, возражения и уведомления направляются Владельцу
        Сервиса на электронный адрес <b>info@enroller.ru</b>
      </p>
    </div>
  </div>
</template>

<script>

export default {
  name: "RulesOnlineEnroll",
  components: {},
  data() {
    return {

    };
  },
  props: {

  },
  watch: {

  },
  computed: {

  },
  methods: {

  },
  created() {

  }
};
</script>

<style scoped lang="less">

</style>
