<template>
  <div class="not-found">

    <div class="not-found--container">
      <div>
        <h1 style="font-size: 98px;">404</h1>
      </div>
      <div>
         Запрашиваемая страница не найдена
      </div>
    </div>

  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "NotFound",
  components: {

  },
  data() {
    return {

    };
  },
  computed: {

  },
  methods: {

  },
  created() {

  }
};
</script>

<style scoped lang="less">
.not-found--container {
  width: 500px;
  min-height: 65vh;
  padding-top: 70px;
  margin: 0 auto 60px auto;
  text-align: center;
}

@media (max-width: 700px) {
  .not-found--container {
    width: 98%;
  }
}
</style>
