const back_common = {
  state: {
    backLink: false
  },
  mutations: {
    setLink(state, link) {
      state.backLink = link;
    },
    removeLink(state) {
      state.backLink = false;
    }
  },
  actions: {
    setBackLink({ commit }, link) {
      commit("setLink", link);
    },
    removeBackLink({ commit }) {
      commit('removeLink');
    }
  }
};

export default back_common;
