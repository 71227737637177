<template>
  <div v-if="staff.length && !blockShow" class="select-staff--container no-select shadow-card">
    <div class="select-staff--title">Выбрать сотрудника:</div>
    <div class="select-staff--list">
      <ul class="select-staff--ul cursor-pointer">
        <li
          @click="selectStaff(item.id)"
          class="select-staff--li"
          v-for="item in staff"
          :key="item.id"
        >
          <div
            class="select-line"
            v-bind:class="{ active: item.active === true }"
          ></div>
          <div class="avatar">
            <div class="anystaff" v-if="item.id === -1">
              <img
                  v-for="(avtr, index) in avatarsForAnyStaff"
                  :key="index"
                  :src="avtr"
                  :class="{one: index === 0, two: index === 1, three: index === 2}"
              >
            </div>
            <div class="normal" v-else>
              <img :src="item.avatar" />
            </div>
          </div>
          <div class="data">
            <div class="name" :class="{'any-staff': item.id === -1}">{{ item.name }}</div>
            <div class="profession">{{item.profession}}</div>
            <div v-if="item.nearest.length > 0" class="schedule">
              {{ item.nearest }}
            </div>
          </div>
          <div class="checked">
            <div v-if="!item.active" class="check_circle_false_orange"></div>
            <div v-if="item.active" class="check_circle_true_orange"></div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// import Formatter from "../../libraries/Formatter";
//
// const formatter = new Formatter();

// import axios from "axios";

import axios from "axios";
import {mapState} from "vuex";

export default {
  name: "SelectStaff",
  components: {},
  data() {
    return {
      blockShow: true, // Заблокировать отображение. Делаем во время автовыбора, чтобы не было скачка
      staff: [],
      routeParams: this.$route.params,
    };
  },
  props: {
    services: Array, // Список выбранных услуг
    selectReset: Number, // Сброс данных компонента
    componentReset: Number,
    rerender: Boolean,
    rerenderForce: Number
  },
  watch: {
    rerender: function(val) {
      if (val === true && !this.staff.length) {
        this.createdComponent();
      }
    },
    rerenderForce: function(){
      this.createdComponent();
    },
    componentReset: function() {
      this.resetComponent();
    },
    selectReset: function() {
      this.resetSelect();
    }
  },
  computed: {
    avatarsForAnyStaff(){
      let result = [];

      let z = 1;
      for(let key in this.staff){
        if(z === 4){
          break
        }
        if(this.staff[key].id === -1){
          continue;
        }
        result[result.length] = this.staff[key].avatar;
        z++;
      }
      return result.reverse();
    },
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
  },
  methods: {
    getServicesIds() {
      let result = {
        services: [],
        modifications: []
      };
      for (let key in this.services) {
        if(+this.services[key].modification_id === 0){
          result.services[result.services.length] = this.services[key].service_id;
        }else{
          result.modifications[result.modifications.length] = this.services[key].modification_id;
        }
      }
      return result;
    },
    // Добавляет необходимые для рендерига параметры в полученных от сервера данных
    filteredGetData(data) {
      let result = data;
      for (let key in result) {
        result[key].active = false;
      }
      return result;
    },
    selectStaff(staff) {
      for (let key in this.staff) {
        if (+this.staff[key].id === +staff) {
          this.staff[key].active = true;
        } else {
          this.staff[key].active = false;
        }
      }
      this.emitStaff();
    },
    emitStaff() {
      let result = null;
      for (let key in this.staff) {
        if (this.staff[key].active === true) {
          result = this.staff[key];
        }
      }
      this.$emit("selectStaff", result);
    },
    emitStaffAndNext() {
      let result = null;
      for (let key in this.staff) {
        if (this.staff[key].active === true) {
          result = this.staff[key];
        }
      }
      this.$emit("selectStaffAndNext", result);
    },
    resetSelect() {
      for (let key in this.staff) {
        this.staff[key].active = false;
      }
      this.emitStaff();
    },
    resetComponent() {
      this.staff = [];
      this.emitStaff();
    },
    checkForOneStaff(){
      this.blockShow = true;
      if(this.staff.length === 1){
        this.selectStaff(this.staff[0].id);
        this.emitStaffAndNext();
      }
      this.blockShow = false;
    },
    createdComponent() {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("list", JSON.stringify(this.getServicesIds()));
      formData.append("company", this.routeParams.company);
      axios
        .post(`/online/get-staff`, formData)
        .then(resp => {
          if(resp.data.length){
            this.staff = this.filteredGetData(resp.data);
            if(+this.all_configs.autoselect_one_staff === 1){
              this.checkForOneStaff();
            }else{
              this.blockShow = false;
            }
          }else{
            this.$emit('setDiagnostic', 'Похоже, что не найдено ни одного сотрудника, который мог бы оказать данную услугу. Сделайте запись по номеру телефона');
          }
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    }
  },
  created() {

  }
};
</script>

<style scoped lang="less">
.select-staff--container{
  padding: 15px 0;
  margin-top: 20px;
}
.select-staff--ul {
  margin: 0;
  padding: 0;
}
.select-staff--li {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;
  list-style-type: none;
  border-bottom: 1px solid #e2e7ed;
  //padding: 10px 0;

  .select-line {
    width: 2px;
  }
  .select-line.active {
    background-color: #f97979;
  }
  .avatar {
    width: 25%;
    text-align: center;
    padding: 3px 0;
    position: relative;
    line-height: 55px;

    .anystaff{
      line-height: 55px;
      margin: auto;
      margin: 0 auto;
      //width: 90px;
      //display: flex;
      //flex-wrap: nowrap;
      //justify-content: center;
      //flex-direction: row;

      img {
        //display: inline-block;
        width: 50px;
        position: absolute;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        //border: 2px solid gray;
        border-radius: 25px;
        box-shadow: -1px 1px 5px 1px rgba(34, 60, 80, 0.2);

        &.one{
          left: -20px;
        }
        &.two{
          right: 0;
        }
        &.three{
          right: -24px;
        }
      }
    }
    .normal{
      img {
        box-shadow: -1px 1px 5px 1px rgba(34, 60, 80, 0.2);
        border-radius: 25px;
        width: 50px;
        position: absolute;
        margin: auto;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
      }
    }
  }
  .data {
    width: 60%;
    padding: 10px 4px;
    color: #4f4f4f;

    .name {
      font-size: 15px;

      &.any-staff{
        font-weight: 400;
        font-weight: 500;
      }
    }
    .profession{
      color: gray;
      font-size: 13px;
      margin-top: 4px;
    }
    .schedule {
      color: gray;
      margin-top: 6px;
      font-size: 11px;
    }
  }
  .checked {
    text-align: center;
    width: 15%;
    line-height: 53px;
    position: relative;

    .check_circle_false_orange, .check_circle_true_orange{
      position: absolute;
      margin: auto;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
}
.select-staff--li:hover {
  background-color: #f5f5f6;
}
.select-staff--title {
  text-align: center;
  color: #4f4f4f;
  font-size: 16px;
  margin-bottom: 30px;
}
</style>
