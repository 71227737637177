import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import axios from "axios";
import VueTheMask from "vue-the-mask";
import config from "@/config";
import { LMap, LTileLayer, LMarker, LCircle , LIcon, LPopup} from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';


Vue.use(VueTheMask);

// FONT AWESOME
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(fas);
Vue.component("f-awesome", FontAwesomeIcon);

// map
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-circle', LCircle);
Vue.component('l-icon', LIcon);
Vue.component('l-popup', LPopup);

Vue.prototype.$http = axios;
Vue.prototype.$http.defaults.baseURL = config.rest_point;

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
