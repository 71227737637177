<template>
  <div class="done-enroll--container shadow-card">
    <div class="text">
      Поздравляем! Вы успешно записаны
    </div>
    <div class="close mrg-t-15">
      Можете закрыть окно
    </div>
    <div class="repeat mrg-t-15">
      <div @click="repeatEnroll" class="btn btn-sm btn-orange">Записаться снова</div>
    </div>
  </div>
</template>

<script>

export default {
  name: "DoneEnroll",
  components: {},
  data() {
    return {

    };
  },
  props: {

  },
  watch: {

  },
  computed: {

  },
  methods: {
    repeatEnroll(){
      this.$emit('repeatEnroll');
    }
  },
  created() {

  }
};
</script>

<style scoped lang="less">
.done-enroll--container{
  margin-top: 20px;
  text-align: center;

  .text{
    font-size: 18px;
    color: #333;
  }
  .close{
    font-size: 18px;
    color: #333;
  }
}
</style>
