<template>
  <div v-if="diffTimezoneComputed === 1" class="diff-timezone-block--container">
    <div class="text">
      <b>Обратите внимание!</b> Время указано в часовом поясе <b>"{{companyTimezoneName}}"</b>, а ваш
      часовой пояс определился, как <b>"{{currentTimezoneName}}"</b>. Учитывайте данную разницу при
      выборе
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();


export default {
  name: "DiffTimezoneBlock",
  components: {},
  data() {
    return {
      timezones: [
        {id: -8, name: '-08:00 (Pacific Time)'},
        {id: -7, name: '-07:00 (Arizona)'},
        {id: 1, name: '+01:00 (Варшава)'},
        {id: 2, name: '+02:00 (Калининград)'},
        {id: 3, name: '+03:00 (Москва)'},
        {id: 4, name: '+04:00 (Самара)'},
        {id: 5, name: '+05:00 (Екатеринбург)'},
        {id: 6, name: '+06:00 (Новосибирск/Омск)'},
        {id: 7, name: '+07:00 (Красноярск'},
        {id: 8, name: '+08:00 (Иркутск)'},
        {id: 9, name: '+09:00 (Якутск)'},
        {id: 10, name: '+10:00 (Владивосток/Магадан)'},
        {id: 11, name: '+11:00 (Среднеколымск)'},
        {id: 12, name: '+12:00 (Камчатка)'},
      ],
      clientTimezone: 3
    };
  },
  props: {

  },
  watch: {

  },
  computed: {
    currentTimezoneName(){
      let result = '';
      for(let key in this.timezones){
        if(this.timezones[key].id == this.clientTimezone){
          result = this.timezones[key].name;
        }
      }
      return result;
    },
    companyTimezoneName(){
      let result = '';
      for(let key in this.timezones){
        if(this.timezones[key].id == this.companyTimezone){
          result = this.timezones[key].name;
        }
      }
      return result;
    },
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
    diffTimezoneComputed(){
      let result = 0;

      let companyTimezone = +this.all_configs.timezone;

      if(this.clientTimezone !== companyTimezone){
        result = 1;
      }
      return result;
    },
    companyTimezone(){
      let result = '';
      result = +this.all_configs.timezone;
      return result;
    }
  },
  methods: {

  },
  created() {
    let nowDate = new Date();
    this.clientTimezone = formatter.timezoneJsToNumber(nowDate.getTimezoneOffset());
  }
};
</script>

<style scoped lang="less">
.diff-timezone-block--container{
  margin-top: 20px;
  background-color: #ffd5d5;
  text-align: center;
  padding: 20px 15px;

  .text{
    color: #4F4F4F;
    font-size: 15px;
  }
}
</style>
