<template>
  <div class="staff">
    <div class="staff--back-block">
      <div class="staff--container">
        <div v-if="errorText" class="notif-box danger">
          {{errorText}}
        </div>
        <div v-if="staff" class="enroll-labels--staff">
          <head-staff-card
              :img="staff.avatar"
              :name="staff.name"
              :profession="staff.profession"
          ></head-staff-card>
        </div>
        <div class="staff--mode shadow-card mrg-t-10">
          <mode-journal 
            @selectJournalMode="selectMode" 
            :modeList="modeList"
            :modeActive="mode"
          ></mode-journal>
        </div>
        <div v-if="mode === 'day'" class="staff--labels shadow-card mrg-t-10">
          <enroll-labels
              :show="true"
              class="mrg-t-15"
          ></enroll-labels>
        </div>
        <div v-if="mode === 'week'" class="staff--labels shadow-card mrg-t-10">
          <enroll-labels-week :staff="[staff]"></enroll-labels-week>
        </div>
        <div v-if="mode === 'month'" class="staff--labels shadow-card mrg-t-10">
          <journal-month :staff="[staff]"></journal-month>
        </div>
        <div v-if="!errorText" class="staff--instruction text-center mrg-t-25">
          <div>Узнай, как добавить данную страницу на главный экран своего устройства</div>
          <div>
            <div @click="instrIosPopup.show = true" class="btn btn-sm btn-outline-info">iOS</div>
            <div @click="instrAndroidPopup.show = true" class="btn btn-sm btn-outline-info">Android</div>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="text">Сервис записи онлайн <a class="text-link" href="https://enroller.ru" target="_blank">Enroller</a></div>
        <div class="copyright">© Enroller. 2018 - {{nowYear}}</div>
      </div>

      <popup
          :closeButton="instrIosPopup.closeButton"
          :show="instrIosPopup.show"
          @closePopup="closeInstrIosPopup"
      >
        <div slot="header">Инструкция iOS</div>
        <div slot="body">
          <p>
            Для того, чтобы добавить данную страницу на главный экран устройства, необходимо
            открыть ее в браузере <b>Safari</b>.
          </p>
          <p>
            Нажимаем на иконку поделиться
          </p>
          <div class="staff--img-p">
            <img src="../assets/img/staff_instr/ios_1.jpeg">
          </div>
          <p>
            Далее нажимаем <b>"На экран Домой"</b>
          </p>
          <div class="staff--img-p">
            <img src="../assets/img/staff_instr/ios_2.jpeg">
          </div>
          <p>
            После чего, данная страница будет доступна с главного экрана вашего устройства и будет вести себя так,
            будто это установленное приложение.
          </p>
        </div>
      </popup>


      <popup
          :closeButton="instrAndroidPopup.closeButton"
          :show="instrAndroidPopup.show"
          @closePopup="closeInstrAndroidPopup"
      >
        <div slot="header">Инструкция Android</div>
        <div slot="body">
          <p>
            Для того, чтобы добавить данную страницу на главный экран устройства, необходимо
            открыть ее в браузере <b>Chrome</b>.
          </p>
          <p>
            Нажимаем на иконку <b>МЕНЮ</b> в правом верхнем углу
          </p>
          <div class="staff--img-p">
            <img src="../assets/img/staff_instr/android_1.jpeg">
          </div>
          <p>
            Далее нажимаем <b>"Добавить на главный экран"</b>
          </p>
          <div class="staff--img-p">
            <img src="../assets/img/staff_instr/android_2.jpeg">
          </div>
          <p>
            После чего, данная страница будет доступна с главного экрана вашего устройства и будет вести себя так,
            будто это установленное приложение.
          </p>
        </div>
      </popup>

      <!-- <set-manifest :short_name="manifestShortName"></set-manifest> -->
      <preloader></preloader>
      <notificator></notificator>
      <update-new-version-manual></update-new-version-manual>
    </div>
  </div>
</template>

<script>

import Preloader from "@/components/Preloader";
import Notificator from "@/components/Notificator";
import axios from "axios";
import EnrollLabels from "@/components/EnrollLabels";
import HeadStaffCard from "@/components/HeadStaffCard";
import Formatter from "@/components/libraries/Formatter";
import Popup from "@/components/Popup";
import SetManifest from '../components/SetManifest.vue';
import ModeJournal from "../components/staff/ModeJournal.vue";
import EnrollLabelsWeek from "../components/staff/EnrollLabelsWeek.vue";
import JournalMonth from '../components/staff/JournalMonth.vue';
import UpdateNewVersionManual from '../components/UpdateNewVersionManual.vue';

const formatter = new Formatter();

export default {
  name: 'Staff',
  components: {
    Popup,
    HeadStaffCard,
    EnrollLabels,
    Notificator,
    Preloader,
    SetManifest,
    ModeJournal,
    EnrollLabelsWeek,
    JournalMonth,
    UpdateNewVersionManual
},
  data(){
    return {
      mode: "",
      modeList: [
        {
          name: "Месяц",
          val: "month"
        },
        {
          name: "Неделя",
          val: "week"
        },
        {
          name: "День",
          val: "day"
        }
      ],
      staff: {},
      manifestShortName: '',
      params: this.$route.params,
      instrIosPopup: {
        show: false,
        closeButton: "Закрыть",
      },
      instrAndroidPopup: {
        show: false,
        closeButton: "Закрыть",
      },
      errorText: null
    }
  },
  props: {

  },
  computed: {
    nowYear(){
      let result = '';
      let nowDate = new Date();
      result = nowDate.getFullYear();
      return result;
    }
  },
  methods: {
    selectMode(val) {
      this.mode = val;
      localStorage.setItem('journal_mode', val);
    },
    closeInstrIosPopup(){
      this.instrIosPopup.show = false;
    },
    closeInstrAndroidPopup(){
      this.instrAndroidPopup.show = false;
    },
    setNowRusDate() {
      let result = "";
      if (this.nowDate != "") {
        let phpDate = formatter.jsObjectToPhp(this.nowDate);
        result = formatter.phpDateToRus(phpDate);
      }
      this.nowRusDate = result;
    },
    getStaff(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("company_id", this.params.companyId);
      formData.append("hash", this.params.hash);
      axios
          .post(`/online/get-staff-by-hash`, formData)
          .then(resp => {
            this.staff = resp.data;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.errorText = err.response.data.message;
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },

  },
  created() {
    this.getStaff();
    this.manifestShortName = 'Мой график';

    let modeStorage = localStorage.getItem('journal_mode');
    if(modeStorage === null){
      localStorage.setItem('journal_mode', 'month');
      this.selectMode('month');
    }else{
      this.selectMode(modeStorage);
    }
  }
}
</script>

<style scoped lang="less">
.staff--img-p{
  text-align: center;
  &>img{
    width: 50%;
  }
}
.enroll-labels--list{
  margin-top: 10px;
  background-color: #fff;
}
.staff--instruction{
  padding: 15px 5px;
  background-color: #fff;
}
.staff{
  padding-top: 1px;
  padding-bottom: 55px;
  min-height: 100vh;
  background-color: #F0F4F9;

  .title{
    margin-top: 10px;
    padding: 20px 5px;
    background-color: #fff;
    text-align: center;
    color: #4f4f4f;
    font-size: 16px;
  }
}
.staff--back-block{
  max-width: 600px;
  margin: 0 auto;
}
.staff--container{
  min-height: 75vh;
  padding-top: 1px;
}
@media (max-width: 600px) {

}
</style>
