<template>
  <div class="enroll-labels--content no-select">
    <div v-if="data.date" class="enroll-labels--container">
      <div class="enroll-labels--list">
        <div class="date">
          <div @click="prevDay">
            <f-awesome icon="arrow-left"></f-awesome>
          </div>
          <div>{{ datePhpToRus(date) }}</div>
          <div @click="nextDay">
            <f-awesome icon="arrow-right"></f-awesome>
          </div>
        </div>
        <div class="schedule-info">
          <div v-if="data.result == 'ok'">
            Рабочий день с {{ minuteToTime(schedule.time_from) }} до
            {{ minuteToTime(schedule.time_to) }}
          </div>
          <div v-if="data.result == 'error'">
            {{ data.text }}
          </div>
        </div>
        <div v-if="data.result == 'ok'" class="labels">
          <div class="items" v-for="(item, index) in labels" :key="index">
            <div
                class="free item"
                v-bind:class="{ progress: item.progress === true }"
                v-if="item.type == 'free'"
            >
              <div class="head">
                <div class="text">
                  Окно {{ minuteToTime(item.time_from) }} -
                  {{ minuteToTime(item.time_to) }}
                  <div class="duration_window">
                    ({{ getDurationWindow(item.time_from, item.time_to) }})
                  </div>
                </div>
              </div>
            </div>
            <div class="break item" v-if="item.type == 'break'">
              Перерыв {{ minuteToTime(item.time_from) }} -
              {{ minuteToTime(item.time_to) }}
            </div>
            <div
                class="enroll item"
                v-bind:class="{
              new: item.confirm == 0,
              confirm: item.confirm == 1,
              done: item.confirm == 2,
              canceled: item.confirm == 3,
              progress: item.progress === true
            }"
                v-if="item.type == 'enroll'"
            >
              <div class="head">
                <div class="action">

                </div>
                <div class="text">
                  {{ minuteToTime(item.time_from) }} -
                  {{ minuteToTime(item.time_to) }}
                </div>
              </div>
              <div
                  v-show="true"
                  class="more"
              >
                <div v-if="+item.is_group === 0" class="client_name">
                  {{ item.enrollClients[0].clients.name }} {{ item.enrollClients[0].clients.surname }}
                  <span v-if="item.enrollClients[0].clients.sale">(скидка {{item.enrollClients[0].clients.sale}}%) </span>
                  <span v-if="+item.enrollClients[0].clients.is_unwanted === 1"><b>(нежелательный клиент!)</b></span>
                </div>
                <div v-if="+item.is_group === 1" class="client_name">
                  <span class="text-bold">{{ calculateActiveGroupCount(item.enrollClients) }}</span> клиента (-ов) в групповой записи
                </div>
                
                <div v-if="item.enrollPosition && item.enrollPosition[0].service_name" class="client_services">
                  <b>Услуги:</b>
                  <span v-for="service in item.enrollPosition" :key="service.id"
                  > (<b>{{ service.category_name }}</b>) {{ service.service_name }};
                </span>
                </div>
                <div v-if="item.comments" class="comment_company">
                  <b>Комментарий компании:</b> {{item.comments.comment_company}}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      </div>

  </div>
</template>

<script>
import Formatter from "@/components/libraries/Formatter";
import axios from "axios";

const formatter = new Formatter();

export default {
  name: "EnrollLabels",
  components: {},
  mixins: [],
  data() {
    return {
      data: {},
      nowDate: new Date(),
      nowRusDate: '',
      date: "",
      schedule: {},
      labels: [],
      intervalProgress: false,
      params: this.$route.params,
      editEnrollPopup: {
        show: false,
        // closeButton: 'Отмена',
        size: "lg",
        header: ""
      },
      selectedEnrollId: null,
      fastNewMode: "new",
      wantTime: 0,
      openEnroll: false, // Раскрытие ярлыком с записями
    };
  },
  props: {
    show: Boolean
  },
  watch: {
    data: function(val) {
      this.setLabelsData(val);
    },
    show: function(val) {
      if (val) {
        this.setProgress();
        this.intervalProgress = setInterval(this.setProgress, 2000);
      } else {
        clearInterval(this.intervalProgress);
      }
    }
  },
  computed: {
    fastNewDefaultDate() {
      let result = "";
      if (this.date != "") {
        result = formatter.phpDateToRus(this.date);
      }
      return result;
    },
  },
  methods: {
    calculateActiveGroupCount(data){
      let result = 0;
      for(let key in data){
        if(+data[key].is_canceled === 0){
            if(data[key].clients){
              result++;
            }
          }
      }
      return result;
    },
    setLabelsData(data) {
      this.labels = data.response;
      this.date = data.date;
      if (typeof data.schedule !== "undefined") {
        this.schedule = data.schedule;
      }
      for (let key in this.labels) {
        this.$set(this.labels[key], "show_more", this.openEnroll);
        this.$set(this.labels[key], "progress", false);
      }
      this.setProgress();
    },
    getDurationWindow(time_from, time_to) {
      let result = formatter.minuteSplitHumanHourMinute(time_to - time_from);
      return result;
    },
    minuteToTime(minutes) {
      return formatter.minuteToTime(minutes);
    },
    datePhpToRus(date){
      return formatter.phpDateToRus(date);
    },
    setProgress() {
      let nowDate = new Date();
      // Проверим дату. Если сегодня не совпадает с открытой датой, то завершим setInterval
      let openDate = formatter.phpDateToJSObject(this.date);
      let nowOnlyDate = formatter.paramsDateToJSObject(
        nowDate.getDate(),
        nowDate.getMonth(),
        nowDate.getFullYear()
      );
      if (openDate.getTime() !== nowOnlyDate.getTime()) {
        clearInterval(this.intervalProgress);
        return false;
      }
      let minutes = formatter.hourMinuteToMinute(
        nowDate.getHours(),
        nowDate.getMinutes()
      );
      for (let key in this.labels) {
        if (
          minutes >= +this.labels[key].time_from &&
          minutes < +this.labels[key].time_to
        ) {
          this.$set(this.labels[key], "progress", true);
        } else {
          this.$set(this.labels[key], "progress", false);
        }
      }
    },
    prevDay() {
      this.nowDate.setDate(this.nowDate.getDate() - 1);
      this.setNowRusDate();
      this.getLabels();
    },
    nextDay() {
      this.nowDate.setDate(this.nowDate.getDate() + 1);
      this.setNowRusDate();
      this.getLabels();
    },
    setNowRusDate() {
      let result = "";
      if (this.nowDate != "") {
        let phpDate = formatter.jsObjectToPhp(this.nowDate);
        result = formatter.phpDateToRus(phpDate);
      }
      this.nowRusDate = result;
    },
    getLabels(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("company_id", this.params.companyId);
      formData.append("hash", this.params.hash);
      let phpDate = formatter.jsObjectToPhp(this.nowDate);
      formData.append("date", phpDate);
      axios
          .post(`/online/get-labels-for-staff`, formData)
          .then(resp => {
            this.data = resp.data;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.errorText = err.response.data.message;
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },

  },
  created() {
    this.setNowRusDate();
    this.getLabels();
  }
};
</script>

<style scoped lang="less">
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  100% {
    box-shadow: 0px 0px 24px 0px rgba(65, 162, 252, 1);
  }
}
.enroll-labels--container {
  padding-bottom: 70px;
  //background-color: #fff;

  .actions{
    padding-top: 10px;
    text-align: center;

    &>div{
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .date {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    flex-direction: row;
    text-align: center;
    font-size: 18px;
    color: #333333;
    //background-color: #f0f4f9;
    padding: 10px 0;
    box-sizing: border-box;

    &>div:nth-of-type(1){
      width: 20%;
      cursor: pointer;
    }
    &>div:nth-of-type(2){
      width: 40%;
    }
    &>div:nth-of-type(3){
      width: 20%;
      cursor: pointer;
    }
  }
  .schedule-info {
    text-align: center;
    font-size: 12px;
    padding: 3px 0;
    box-sizing: border-box;
    border-bottom: 1px solid #c4c4c4;
  }

  .items {
    .item {
      border-radius: 6px;
      margin-top: 25px;
      /*box-sizing: border-box;*/
      font-size: 14px;
      cursor: pointer;

      &.new {
        background-color: #ff3d3d;
      }
      &.confirm {
        background-color: #fecf63;
      }
      &.done {
        background-color: #36deac;
      }
      &.canceled {
        background-color: #9946e7;
      }
      &.free {
        background-color: #f0f4f9;
      }
      &.break{
        text-align: center;
        font-size: 13px;
        color: #4F4F4F;
        text-decoration: underline;
      }
      &.progress {
        box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
        animation: pulse 1s infinite;
      }

      .head {
        border-radius: 6px;
        position: relative;
        box-shadow: 0px 5px 9px 0px rgba(0, 0, 0, 0.25);

        .action {
          width: 30px;
          position: absolute;
          height: 50px;
          top: 0;
          left: 4px;
          bottom: 0;
          margin: auto;

          & > div {
            position: absolute;
            top: 0;
            bottom: 0;
            margin: auto;
          }
        }
        .text {
          font-size: 14px;
          color: #4f4f4f;
          text-align: center;
          font-weight: 500;
          padding: 10px 0;

          .duration_window {
            font-size: 10px;
          }
        }
      }

      .more {
        color: #333333;
        font-size: 13px;
        padding: 8px 5px 8px 15px;
        box-sizing: border-box;
      }
    }
  }
}
</style>
