<template>
  <div class="select-calendar-and-interval--container no-select">
    <div class="select-calendar-and-interval--calendar">
      <select-calendar
        :active="activeDates"
        :reset="resetCalendar"
        @selectDate="selectDate"
      ></select-calendar>
    </div>
    <div class="select-calendar-and-interval--diff-timezone">
      <diff-timezone-block></diff-timezone-block>
    </div>
    <div class="select-calendar-and-interval--intervals">
      <select-interval
        @selectTime="emitTime"
        :intervals="selectIntervals"
        :reset="resetIntervals"
      ></select-interval>
    </div>
  </div>
</template>

<script>
 import Formatter from "@/components/libraries/Formatter";
const formatter = new Formatter();

import scrollTo from "@/components/mixins/scrollTo";

import SelectCalendar from "./SelectCalendar";
import axios from "axios";
import SelectInterval from "./SelectInterval";
import DiffTimezoneBlock from "@/components/DiffTimezoneBlock";

export default {
  name: "SelectCalendarAndInterval",
  components: {DiffTimezoneBlock, SelectInterval, SelectCalendar },
  mixins: [scrollTo],
  data() {
    return {
      data: [],
      selectIntervals: [],
      resetCalendar: 0,
      resetIntervals: 0,
      routeParams: this.$route.params
    };
  },
  props: {
    staff: Number,
    duration: Number,
    selectedServices: Array,
    rerender: Number,
    reset: Number
  },
  watch: {
    rerender: function() {
      this.getSchedule();
    },
    reset: function() {
      // this.data = [];
      // this.resetCalendar = this.resetCalendar +1;
      this.resetIntervals = this.resetIntervals + 1;
      this.emitDate("");
      this.emitTime("");
    }
  },
  computed: {
    activeDates() {
      let result = [];
      for (let key in this.data) {
        result[result.length] = this.data[key]["date"];
      }
      return result;
    }
  },
  methods: {
    getServicesIds() {
      let result = {
        services: [],
        modifications: []
      };
      for (let key in this.selectedServices) {
        if(+this.selectedServices[key].modification_id === 0){
          result.services[result.services.length] = this.selectedServices[key].service_id;
        }else{
          result.modifications[result.modifications.length] = this.selectedServices[key].modification_id;
        }
      }
      return result;
    },
    getSchedule() {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("staff", this.staff);
      formData.append("duration", this.duration);
      formData.append("company", this.routeParams.company);
      formData.append("service_list", JSON.stringify(this.getServicesIds()));

      this.resetIntervals = this.resetIntervals + 1;
      this.emitDate("");
      this.emitTime("");
      this.selectIntervals = [];
      axios
        .post(`/online/get-intervals`, formData)
        .then(resp => {
          this.data = resp.data;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    selectDate(val) {
      if (val) {
        for (let key in this.data) {
          //let activeData = new Date(this.data[key].date);
          let activeData = formatter.phpDateToJSObject(this.data[key].date);
          if (
            +activeData.getDate() === +val.day &&
            +activeData.getMonth() === +val.month &&
            +activeData.getFullYear() === +val.year
          ) {
            this.selectIntervals = this.data[key].intervals;
            this.emitDate(this.data[key].date);
            this.emitTime("");

            let needBlock = document.getElementsByClassName('select-calendar-and-interval--diff-timezone')[0];
            let box = needBlock.getBoundingClientRect();
            let needOffset = box.top + pageYOffset;
            this.scrollToSmoothly(needOffset, 300);
          }
        }
      } else {
        this.selectIntervals = [];
      }
    },
    emitDate(date) {
      this.$emit("selectDate", date);
    },
    emitTime(time) {
      this.$emit("selectTime", time);
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
.select-calendar-and-interval--calendar {
  //width: 70%;
  margin: 0 auto;
}
.select-calendar-and-interval--intervals {
  //width: 70%;
  margin: 20px auto 0 auto;
}
</style>
