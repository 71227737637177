<template>
  <div class="enroll">
    <div class="enroll--back-block">
      <div class="enroll--container">
        <div v-if="existsError > 0" class="notif-box danger">
          {{errorText}}
        </div>
        <div v-if="existsError == 0">
          <div class="company-info">
            <company-info :stop-load="true" :set-data="companyData"></company-info>
          </div>
          <div class="enroll-info mrg-t-10">
            <enroll-page-info :enroll-data="enrollData"></enroll-page-info>
          </div>
          <div class="actions mrg-t-10">
            <div v-if="cancelSubmitted == 0" @click="clickCancel++" class="btn btn-sm btn-orange">Отменить запись</div>
            <a :href="companyData.link" target="_blank" class="btn btn-sm btn-success">Записаться еще</a>
            <a
                class="btn btn-sm btn-outline-info"
                :href="googleCalendarLink"
               target="_blank" rel="nofollow">В Google-календарь
            </a>
          </div>
          <div v-if="clickCancel > 0 && cancelSubmitted == 0" class="text-cancel">
            <div class="reason">
              <div class="input-block">
                <label>Вы можете указать причину отмены</label>
                <textarea rows="4" v-model="reasonCanceled"></textarea>
              </div>
              <div class="input-block">
                <div @click="cancelEnroll" class="btn btn-sm btn-success">Отменить</div>
              </div>
            </div>
          </div>
          <div v-if="cancelSubmitted > 0" class="cancel-submitted">
            Запись успешно отменена. Можете закрыть страницу
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="text">Сервис записи онлайн <a class="text-link" href="https://enroller.ru" target="_blank">Enroller</a></div>
        <div class="copyright">© Enroller. 2018 - {{nowYear}}</div>
      </div>
      <preloader></preloader>
      <notificator></notificator>
      <update-new-version-manual></update-new-version-manual>
    </div>
  </div>
</template>

<script>

import Preloader from "@/components/Preloader";
import Notificator from "@/components/Notificator";
import axios from "axios";
import CompanyInfo from "@/components/CompanyInfo";
import EnrollPageInfo from "@/components/EnrollPageInfo";
import UpdateNewVersionManual from '../components/UpdateNewVersionManual.vue';

export default {
  name: 'Enroll',
  data(){
    return {
      params: this.$route.params,
      companyData: {
        name: '',
        address: '',
        city: '',
        image: '',
        link: ''
      },
      enrollData: {},
      existsError: 0,
      errorText: '',
      clickCancel: 0,
      reasonCanceled: '',
      cancelSubmitted: 0
    }
  },
  components: {
    CompanyInfo,
    Notificator,
    Preloader,
    EnrollPageInfo,
    UpdateNewVersionManual
  },
  props: {

  },
  computed: {
    nowYear(){
      let result = '';
      let nowDate = new Date();
      result = nowDate.getFullYear();
      return result;
    },
    googleCalendarLink(){
      let result = 'http://www.google.com/calendar/event?action=TEMPLATE&text=';
      result += 'Запись в ' +this.companyData.name;
      result += '&dates=' +this.enrollData.date_google_from +'/' +
          this.enrollData.date_google_to;

      return result;
    }
  },
  methods: {
    getData(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("server_id", this.params.server);
      formData.append("hash", this.params.hash);
      axios
        .post(`/online/get-data-enroll-page`, formData)
        .then(resp => {
          this.enrollData = resp.data.enroll;
          this.companyData = resp.data.company;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("stopPreloader");
          this.existsError++;
          this.errorText = err.response.data.message;
        });
    },
    cancelEnroll(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("server_id", this.params.server);
      formData.append("hash", this.params.hash);
      formData.append("reason", this.reasonCanceled);
      axios
          .post(`/online/cancel-enroll`, formData)
          .then(() => {
            this.cancelSubmitted++;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
  },
  created() {
    this.getData();
  }
}
</script>

<style scoped lang="less">
.enroll{
  padding-top: 1px;
  padding-bottom: 55px;
  min-height: 100vh;
  background-color: #F0F4F9;

  .title{
    margin-top: 10px;
    padding: 20px 5px;
    background-color: #fff;
    text-align: center;
    color: #4f4f4f;
    font-size: 16px;
  }
}
.enroll--back-block{
  max-width: 600px;
  margin: 0 auto;
}
.enroll--container{
  min-height: 75vh;
  padding-top: 1px;

  .actions{
    text-align: center;
    padding: 25px 0 25px 0;
    background-color: #fff;

    &>div,a{
      margin-top: 8px;
    }
  }
  .text-cancel{
    padding: 25px;
    background-color: #fff;
  }
  .cancel-submitted{
    padding: 25px;
    background-color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 700;
  }
}

@media (max-width: 600px) {
  .enroll--back-block{
    width: 100%;
  }
}
</style>
