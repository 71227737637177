<template>
  <div v-show="calendar.length > 0" class="j-month--content no-select">
    <div class="journal--bar-container">

      <div class="journal--bar-dates mrg-t-15">
        <div class="left" @click="prevMonth">
          <f-awesome icon="arrow-left"></f-awesome>
        </div>
        <div class="date">
          <b>{{ monthName[nowDate.getMonth()] }}</b
          >, {{ nowDate.getFullYear() }}
        </div>
        <div class="right" @click="nextMonth">
          <f-awesome icon="arrow-right"></f-awesome>
        </div>
      </div>
    
    </div>
    
    <div class="j-month--calendar-container no-select mrg-t-20">
      <div class="j-month--calendar-overflow">
        <div class="j-month--calendar-body j-month--calendar-weeksname">
          <div>Пнд</div>
          <div>Вт</div>
          <div>Ср</div>
          <div>Чтв</div>
          <div>Птн</div>
          <div class="weekend">Сб</div>
          <div class="weekend">Вскр</div>
        </div>
        <div
          v-for="(week, key) in calendar"
          :key="key"
          class="j-month--calendar-body j-month--calendar-week"
        >
          <div
            @click="selectDay(day)"
            class="item"
            v-bind:class="{ selected: day.selected === true }"
            v-for="(day, index) in week"
            :key="index"
          >
            <div class="date" v-if="day.day !== 0">
              <div
                v-bind:class="{
                  today: day.today === true,
                  no_schedule:
                    (!day.schedule.id && day.year != 0) ||
                    day.schedule.weekend == 1
                }"
              >
                {{ day.day }}
              </div>
            </div>
            <div class="info">
              <div v-if="!day.schedule.id && day.year != 0" class="no-schedule">
                Нет расписания
              </div>
              <div v-if="day.schedule.weekend == 1" class="no-schedule">
                Выходной
              </div>
              <div v-if="checkExistsCircles(day.circles)" class="circles">
                <div v-if="day.circles.new" class="circle-block">
                  <div class="circle new">{{ day.circles.new }}</div>
                </div>
                <div v-if="day.circles.confirm" class="circle-block">
                  <div class="circle confirm">{{ day.circles.confirm }}</div>
                </div>
                <div v-if="day.circles.done" class="circle-block">
                  <div class="circle done">{{ day.circles.done }}</div>
                </div>
                <div v-if="day.circles.canceled" class="circle-block">
                  <div class="circle canceled">{{ day.circles.canceled }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <popup
      :closeButton="labelsPopup.closeButton"
      :show="labelsPopup.show"
      @closePopup="closeLabelsPopup"
    >
      <div slot="header">Список</div>
      <div slot="body">
        <enroll-labels-for-month :data="labelsData"></enroll-labels-for-month>
      </div>
    </popup>

  </div>
</template>

<script>
import Formatter from "../libraries/Formatter";
import CalendarMonthData from "../libraries/CalendarMonthData";
import ModeJournal from "./ModeJournal";
import axios from "axios";
import Popup from '../Popup.vue';
import EnrollLabelsForMonth from "./EnrollLabelsForMonth.vue";

const formatter = new Formatter();

export default {
  name: "JournalMonth",
  components: { ModeJournal, Popup, EnrollLabelsForMonth },
  data() {
    return {
      monthName: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь"
      ],
      weekdayName: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
      weekdayNumber: [6, 0, 1, 2, 3, 4, 5],
      nowDate: new Date(),
      dateFrom: null,
      dateTo: null,
      calendar: [],
      staffModel: null,
      labelsData: {},
      labelsPopup: {
        show: false,
        closeButton: 'Закрыть',
      },
      params: this.$route.params,
    };
  },
  props: {
    staff: Array,
  },
  watch: {
    staffModel() {
      this.renderCalendar();
    }
  },
  computed: {
    lastUpdateRus(){
      return (this.lastUpdate.getHours() <= 9? '0'+this.lastUpdate.getHours():this.lastUpdate.getHours()) +':' +
        (this.lastUpdate.getMinutes() <= 9? '0'+this.lastUpdate.getMinutes():this.lastUpdate.getMinutes() ) +':' +
        (this.lastUpdate.getSeconds() <= 9? '0'+this.lastUpdate.getSeconds():this.lastUpdate.getSeconds());
    },
    staff_avatar() {
      let result = null;
      if (this.staffModel !== null) {
        for (let key in this.staff) {
          if (+this.staff[key].id === +this.staffModel) {
            result = this.staff[key].avatar;
          }
        }
      }
      return result;
    }
  },
  methods: {
    closeLabelsPopup(){
      this.labelsPopup.show = false;
    },
    showLabelsPopup(){
      this.labelsPopup.show = true;
    },
    checkExistsCircles(circles) {
      if (circles.new || circles.confirm || circles.done || circles.canceled) {
        return true;
      }
      return false;
    },
    renderCalendar() {
      let monthData = new CalendarMonthData(
        this.nowDate.getFullYear(),
        this.nowDate.getMonth()
      );
      let resultCalendar = monthData.getData();
      this.dateFrom = formatter.dateToPhpFormat(monthData.getDateFrom());
      this.dateTo = formatter.dateToPhpFormat(monthData.getDateTo());

      this.calendar = resultCalendar;
      for (let week in this.calendar) {
        for (let day in this.calendar[week]) {
          this.$set(this.calendar[week][day], "circles", []);
          this.$set(this.calendar[week][day], "schedule", []);
          this.$set(this.calendar[week][day], "selected", false);
        }
      }
      this.getCircles();
    },
    prevMonth() {
      this.nowDate.setDate(1);
      this.nowDate.setMonth(this.nowDate.getMonth() - 1);
      this.renderCalendar();
    },
    nextMonth() {
      this.nowDate.setDate(1);
      this.nowDate.setMonth(this.nowDate.getMonth() + 1);
      this.renderCalendar();
    },
    getCircles() {
      let formData = new FormData();
      formData.append("company_id", this.params.companyId);
      formData.append("hash", this.params.hash);
      formData.append("date_from", this.dateFrom);
      formData.append("date_to", this.dateTo);
      axios
        .post(`/online/get-month-circles`, formData)
        .then(resp => {
          this.setCirclesInCalendar(resp.data);
        })
        .catch(err => {
          clearTimeout(this.intervalUpdateProgress);
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    setCirclesInCalendar(data) {
      for (let circle in data) {
        let dateCircle = formatter.phpDateToJSObject(data[circle].date);
        for (let week in this.calendar) {
          for (let day in this.calendar[week]) {
            if (this.calendar[week][day].year !== 0) {
              let dateCalendar = formatter.paramsDateToJSObject(
                this.calendar[week][day].day,
                this.calendar[week][day].month,
                this.calendar[week][day].year
              );
              if (dateCircle.getTime() === dateCalendar.getTime()) {
                this.$set(
                  this.calendar[week][day],
                  "circles",
                  data[circle].circles
                );
                this.$set(
                  this.calendar[week][day],
                  "schedule",
                  data[circle].schedule
                );
              }
            }
          }
        }
      }
    },
    selectDay(dayObj) {
      if (!dayObj.schedule.id) {
        this.$store.dispatch("addNotification", {
          text: "В этот день нет расписания",
          time: 3,
          color: "danger"
        });
        return false;
      }
      if (dayObj.schedule.weekend == 1) {
        this.$store.dispatch("addNotification", {
          text: "В этот день выходной",
          time: 3,
          color: "danger"
        });
        return false;
      }
      let dateSelected = formatter.paramsDateToJSObject(
        dayObj.day,
        dayObj.month,
        dayObj.year
      );
      for (let week in this.calendar) {
        for (let day in this.calendar[week]) {
          let dateCalendar = formatter.paramsDateToJSObject(
            this.calendar[week][day].day,
            this.calendar[week][day].month,
            this.calendar[week][day].year
          );
          if (dateSelected.getTime() === dateCalendar.getTime()) {
            this.$set(this.calendar[week][day], "selected", true);
            let datePhp = formatter.dateToPhpFormat(dateCalendar);
            this.getLabels(datePhp);
            this.showLabelsPopup();
          } else {
            this.$set(this.calendar[week][day], "selected", false);
          }
        }
      }
    },
    getLabels(date) {
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("company_id", this.params.companyId);
      formData.append("hash", this.params.hash);
      formData.append("date", date);
      axios
        .post(`/online/get-labels-for-staff`, formData)
        .then(resp => {
          this.labelsData = resp.data;
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
  },
  created() {
    if (this.staff.length) {
      this.staffModel = this.staff[0].id;
    }
  },
  beforeDestroy() {
    
  }
};
</script>

<style scoped lang="less">
.j-month--calendar-container {
  width: 100%;
}
.j-month--calendar-overflow {
  overflow: auto;
}
.journal--bar-dates {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  flex-direction: row;
  text-align: center;

  & > div {
    cursor: pointer;
    color: #4f4f4f;
  }

  & > div:nth-of-type(1) {
    width: 50px;
    text-align: right;
    font-size: 17px;
  }
  & > div:nth-of-type(2) {
    width: 50%;
    font-size: 18px;
    text-align: center;
  }
  & > div:nth-of-type(3) {
    width: 50px;
    text-align: left;
    font-size: 17px;
  }
}
.j-month--calendar-body {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;

  & > div {
    display: inline-block;
    text-align: center;
    min-width: 120px;
    max-width: 650px;
    width: 600px;
    /*height: 70px;*/
    border-bottom: 1px solid #c4c4c4;
    /*padding: 5px;*/
    box-sizing: border-box;
    cursor: pointer;
  }
  & > div:nth-of-type(1) {
    border-left: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;
  }
  & > div:not(:nth-of-type(1)) {
    border-right: 1px solid #c4c4c4;
  }
}
.j-month--calendar-weeksname {
  & > div {
    height: 30px;
    background-color: #f0f4f9;
    border-top: 1px solid #c4c4c4;
    color: #333333;
    font-size: 16px;
    line-height: 30px;
  }
  & > div.weekend {
    color: #f97979;
  }
}

.j-month--labels-last-update{
  margin-top: 10px;
    .text{
      font-size: 12px;
      color: gray;
    }
    .hint{
      margin-left: 5px;
    }
}
.j-month--calendar-week {
  & > div {
    height: 100px;
    padding: 10px 0;
  }

  .item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-around;
    flex-direction: row;

    &.selected {
      background-color: #f3f6fb;
    }

    .date {
      width: 30%;
      font-size: 16px;
      color: #333;
      font-weight: 700;

      .no_schedule {
        color: #c4c4c4;
      }
      .today {
        display: inline-block;
        border-bottom: 2px solid #f97979;
      }
    }
    .info {
      width: 60%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: flex-end;
      flex-direction: row;

      .no-schedule {
        color: #c4c4c4;
        font-size: 15px;
        font-weight: 400;
      }

      .circles {
        width: 80px;
        height: 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
        flex-direction: row;

        .circle-block {
          width: 30%;

          .circle {
            width: 20px;
            height: 20px;
            border-radius: 20px;
            margin: 0 auto 9px auto;
            text-align: center;
            font-size: 10px;
            line-height: 20px;
            color: #fff;
            /*letter-spacing: -1px;*/
          }
          .circle.new {
            background-color: #ff3d3d;
          }
          .circle.confirm {
            background-color: #fecf63;
          }
          .circle.done {
            background-color: #36deac;
          }
          .circle.canceled {
            background-color: #6f03d3;
          }
        }
      }
    }
  }
}

</style>
