<template>
  <div v-show="all_configs.hide_phone_number == 0" class="call-block--container shadow-card">
    <div class="text">
      <div v-if="computedTextNumber == 0">
        Не нашли подходящее время? Позвоните, наверняка вам что-нибудь подберут
      </div>
      <div v-if="computedTextNumber == 1">
        Не нашли подходящее время? Звоните, наши специалисты обязательно вам помогут
      </div>
      <div v-if="computedTextNumber == 2">
        Наш номер телефона
      </div>
      <div v-if="computedTextNumber == 3">
        Если у вас возникли вопросы или сложности при записи- звоните!
      </div>
    </div>
    <div class="number mrg-t-10">
      <div>
        {{number}}
        <a class="call-block--button" :href="'tel:' +number"><f-awesome icon="phone"></f-awesome></a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import axios from "axios";

export default {
  name: "CallBlock",
  components: {},
  data() {
    return {
      routeParams: this.$route.params,
      textNumber: 0,
    };
  },
  props: {
    number: String
  },
  watch: {

  },
  computed: {
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
    computedTextNumber(){
      let result = 0;
      if(this.all_configs.service_number_text){
        result = this.all_configs.service_number_text;
      }
      return +result;
    }
  },
  methods: {

  },
  created() {

  }
};
</script>

<style scoped lang="less">
.call-block--button{
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 24px;
  background-color: #36DEAC;
  color: #fff;
  border-radius: 15px;
  font-size: 12px;
}
.call-block--container{
  margin-top: 30px;
  text-align: center;
  padding: 45px 25px;

  .text{
    color: #4F4F4F;
    font-size: 15px;
  }
}
</style>
