<template>
  <div class="select-client--container shadow-card">
    <div v-for="(item, index) in formData" :key="index" class="select-client--data mrg-t-10">
      <div v-show="formData.length >= 2" class="text-right text-bold">
        Клиент {{ index +1 }}
      </div>
      <div v-if="surnameShow" class="input-block mrg-t-10">
        <label>Фамилия <span class="req">*</span></label>
        <input type="text" v-model="item.surname.model" @focus="item.surname.error = ''" @blur="vSurname">
        <div class="form-error">{{item.surname.error}}</div>
      </div>
      <div v-if="nameShow" class="input-block">
        <label>Имя <span class="req">*</span></label>
        <input type="text" v-model="item.name.model" @focus="item.name.error = ''" @blur="vName">
        <div class="form-error">{{item.name.error}}</div>
      </div>
      <div v-if="lastnameShow" class="input-block">
        <label>Отчество <span class="req">*</span></label>
        <input type="text" v-model="item.lastname.model" @focus="item.lastname.error = ''" @blur="vLastname">
        <div class="form-error">{{item.lastname.error}}</div>
      </div>
      <div class="input-block mb-0">
        <label>Номер телефона <span class="req">*</span></label>
        <phone-number
            v-model="item.number.model"
            :default-country-code="+all_configs.country_code"
            :request-validate="requestValidateNumber"
            @ignoreEightValidate="setIgnoreValidateEightInNumber"
            @mask="setPhoneMask"
        ></phone-number>
      </div>
      <div class="input-block">
        <label>
          E-mail
          <span v-if="setting.required_email === 0">(необязательное поле)</span>
          <span v-if="setting.required_email === 1" class="req">*</span>
        </label>
        <input type="text" v-model="item.email.model" @focus="item.email.error = ''" @blur="vEmail">
        <div v-if="setting.required_email === 0" class="hint">Чтобы вы не забыли, когда записались, мы вышлем вам письмо</div>
        <div class="form-error">{{item.email.error}}</div>
      </div>
      <div v-if="setting.add_field == 1" class="input-block">
        <label>
          {{setting.add_field_name}}
          <span v-if="setting.add_field_required === 0">(необязательное поле)</span>
          <span v-if="setting.add_field_required === 1" class="req">*</span>
        </label>
        <input type="text" v-model="item.add_field.model" @focus="item.add_field.error = ''" @blur="vAddField" v-if="setting.add_field_type === 0">
        <textarea v-model="item.add_field.model" @focus="item.add_field.error = ''" @blur="vAddField" v-if="setting.add_field_type === 1"></textarea>
        <div class="form-error">{{item.add_field.error}}</div>
      </div>
      <div class="input-block">
        <label>Комментарий (необязательное поле)</label>
        <textarea rows="6" v-model="item.comment.model" @focus="item.comment.error = ''" @blur="vComment"></textarea>
        <div class="form-error">{{item.comment.error}}</div>
      </div>

      <div 
        v-if="
          setting.remind_before_setting > 0 && 
          setting.remind_before_client_choise > 0 && 
          !isGroup
        "
      >
        <div class="input-block">
          <label for="remind_before">Напомнить мне о записи</label>
          <select
              name="remind_before"
              id="remind_before_setting"
              v-model="item.remindBefore.model"
          >
            <option value="0">Не напоминать</option>
            <option value="1">За 1 час до начала</option>
            <option value="2">За 2 часа до начала</option>
            <option value="3">За 3 часа до начала</option>
            <option value="4">За 4 часа до начала</option>
            <option value="5">За 5 часов до начала</option>
            <option value="6">За 6 часов до начала</option>
            <option value="12">За 12 часов до начала</option>
            <option value="18">За 18 часов до начала</option>
            <option value="24">За 24 часа до начала</option>
          </select>
        </div>
      </div>
    </div>

    <div class="text-center">
      <div 
        v-if="showMoreClient" 
        @click="addMoreClient" 
        class="btn btn-sm btn-info mrg-t-10"
      >
        Добавить +1 клиента
      </div>
      <div 
        @click="deleteLastMoreClient" 
        v-if="showRemoveLastClient" 
        class="btn btn-sm btn-danger mrg-t-10"
      >
        <f-awesome icon="times"></f-awesome> Убрать последнего
      </div>
    </div>

    <div class="input-block mrg-t-30 politic-checkbox-block">
      <label @click="politic.error = ''">
        <input v-model="politic.model" type="checkbox">
        Я ознакомлен с <span @click.prevent="showEnrollRule" class="s-client--politic">правилами онлайн-записи</span>
        и ознакомлен с <span>
        <a href="https://enroller.ru/privacy-policy.html" target="_blank" class="s-client--politic">
          политикой в отношении обработки Персональных Данных через сервис Enroller
        </a>
      </span>
      </label>
      <div class="form-error">{{politic.error}}</div>
    </div>
    <div class="input-block">
      <label @click="notif_rule.error = ''">
        <input v-model="notif_rule.model" type="checkbox">
        Я согласен на получение уведомлений о произведенной записи через сервис Enroller
      </label>
      <div class="form-error">{{notif_rule.error}}</div>
    </div>
    <div class="input-block">
      <div @click="createEnroll" class="btn btn-orange">Записаться</div>
    </div>

    <popup
        :closeButton="enrollRulePopup.closeButton"
        :show="enrollRulePopup.show"
        @closePopup="closeEnrollRulePopup"
    >
      <div slot="header">Правила онлайн-записи через сервис Enroller</div>
      <div slot="body">
        <rules-online-enroll></rules-online-enroll>
      </div>
    </popup>

  </div>
</template>

<script>
import { mapState } from "vuex";

import Validator from "@/components/libraries/Validator";
import Popup from "@/components/Popup";
import PhoneNumber from "./PhoneNumber";
import RulesOnlineEnroll from "./RulesOnlineEnroll";

const validator = new Validator();


export default {
  name: "SelectClient",
  components: {RulesOnlineEnroll, PhoneNumber, Popup},
  data() {
    return {
      requestValidateNumber: 0,
      enrollRulePopup: {
        show: false,
        closeButton: 'Закрыть'
      },
      routeParams: this.$route.params,
      getParams: this.$route.query,
      phoneMask: '',
      setting: {
        fio_fields: 4, // 0- ФИО, 1- И, 2- ИО, 3- ФИ
        phone_mask: '',
        required_email: 0,
        add_field: 0,
        add_field_name: null,
        add_field_type: 0,
        add_field_required: 0,
        remind_before_setting: 0,
        remind_before_client_choise: 0
      },
      formData: [
        {
          surname: {model: '', error: ''},
          name: {model: '', error: ''},
          lastname: {model: '', error: ''},
          number: {model: '', error: ''},
          email: {model: '', error: ''},
          comment: {model: '', error: ''},
          add_field: {model: '', error: ''},
          remindBefore: {model: 0}
        }
      ],

      politic: {model: false, error: ''},
      notif_rule: {model: false, error: ''},

      ignoreEightInNumber: false,
    };
  },
  props: {
    reset: Number,
    isGroup: Boolean,
    show: Boolean,
    groupParticipantsData: Object
  },
  watch: {
    show: function(val){
      if(val){
        let userData = localStorage.getItem('clientData');
        if(userData){
          userData = JSON.parse(userData);
          
          if(this.nameShow && userData.name){
            this.formData[0].name.model = userData.name;
          }
          if(this.surnameShow && userData.surname){
            this.formData[0].surname.model = userData.surname;
          }
          if(this.lastnameShow && userData.lastname){
            this.formData[0].lastname.model = userData.lastname;
          }

          if(userData.number){
            this.formData[0].number.model = userData.number;
          }
          if(userData.email){
            this.formData[0].email.model = userData.email;
          }
        }
      }
    },
    all_configs: function(val){
      this.setting.phone_mask = val.phone_mask;
      this.setting.fio_fields = val.fio_fields;
      this.setting.required_email = val.required_email;
      this.setting.add_field = val.add_field;
      this.setting.add_field_name = val.add_field_name;
      this.setting.add_field_type = val.add_field_type;
      this.setting.add_field_required = val.add_field_required;
      this.setting.remind_before_setting = val.remind_before_setting;
      this.setting.remind_before_client_choise = val.remind_before_client_choise;
      this.formData[0].remindBefore.model = val.remind_before_setting;
    },
  },
  computed: {
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
    showMoreClient(){
      let result = false;
      if(this.isGroup && this.groupParticipantsData.hasOwnProperty('rem_seats')){
        if(+this.groupParticipantsData.rem_seats > this.formData.length){
          result = true;
        }
      }
      return result;
    },
    showRemoveLastClient(){
      let result = false;
      if(this.isGroup && this.formData.length > 1){
        result = true;
      }
      return result;
    },
    surnameShow() {
      let result = false;
      if(this.setting.fio_fields === 0 || this.setting.fio_fields === 3){
        result = true;
      }
      return result;
    },
    nameShow(){
      let result = false;
      if(
        this.setting.fio_fields === 0 || 
        this.setting.fio_fields === 1 || 
        this.setting.fio_fields === 2 || 
        this.setting.fio_fields === 3
      ){
        result = true;
      }
      return result;
    },
    lastnameShow(){
      let result = false;
      if(this.setting.fio_fields === 0 || this.setting.fio_fields === 2){
        result = true;
      }
      return result;
    },
    validateData() {
      let errorCount = 0;
      this.vSurname().result == "error" ? errorCount++ : "";
      this.vName().result == "error" ? errorCount++ : "";
      this.vLastname().result == "error" ? errorCount++ : "";
      this.vNumber().result == "error" ? errorCount++ : "";
      this.vEmail().result == "error" ? errorCount++ : "";
      this.vComment().result == "error" ? errorCount++ : "";
      this.vPolitic().result == "error" ? errorCount++ : "";
      this.vNotifRule().result == "error" ? errorCount++ : "";
      if(+this.setting.add_field === 1){
        this.vAddField().result == 'error' ? errorCount++ : "";
      }

      return errorCount;
    }
  },
  methods: {
    deleteLastMoreClient(){
      this.formData.splice(this.formData.length - 1, 1);
    },
    addMoreClient(){
      this.formData.push(
        {
          surname: {model: '', error: ''},
          name: {model: '', error: ''},
          lastname: {model: '', error: ''},
          number: {model: '', error: ''},
          email: {model: '', error: ''},
          comment: {model: '', error: ''},
          add_field: {model: '', error: ''},
          remindBefore: {model: 0}
        }
      );
    },
    setIgnoreValidateEightInNumber(val){
      this.ignoreEightInNumber = val;
    },
    showEnrollRule(){
      this.enrollRulePopup.show = true;
    },
    closeEnrollRulePopup(){
      this.enrollRulePopup.show = false;
    },
    setPhoneMask(val){
      this.phoneMask = val;
    },
    vSurname(){
      for(let key in this.formData){
        if(this.surnameShow){
          let validate = validator.validate([
            {
              fieldName: "Фамилия",
              value: this.formData[key].surname.model,
              type: "required",
              length: 250
            }
          ]);
          if(validate.result == "error"){
            this.formData[key].surname.error = validate.message;
            return validate;
          }else{
            this.formData[key].surname.error = "";
          }
        }
      }
      return {result: 'ok'};
    },
    vName(){
      for(let key in this.formData){
        if(this.nameShow){
          let validate = validator.validate([
            {
              fieldName: "Имя",
              value: this.formData[key].name.model,
              type: "required",
              length: 250
            }
          ]);
          if(validate.result == "error"){
            this.formData[key].name.error = validate.message;
            return validate;
          }else{
            this.formData[key].name.error = "";
          }
        }
      }
      return {result: 'ok'};
    },
    vLastname(){
      for(let key in this.formData){
        if(this.lastnameShow){
          let validate = validator.validate([
            {
              fieldName: "Отчество",
              value: this.formData[key].lastname.model,
              type: "required",
              length: 250
            }
          ]);
          if(validate.result == "error"){
            this.formData[key].lastname.error = validate.message;
            return validate;
          }else{
            this.formData[key].lastname.error = "";
          }
        }
      }
      return {result: 'ok'};
    },
    vEmail(){
      for(let key in this.formData){
        let validateParams;
        if(+this.setting.required_email === 1){
          validateParams = [
            {
              fieldName: "E-mail",
              value: this.formData[key].email.model,
              type: "required",
              length: 45
            },
            {
              fieldName: "E-mail",
              value: this.formData[key].email.model,
              type: "email",
              length: 45
            }
          ];
        }else{
          validateParams = [
            {
              fieldName: "E-mail",
              value: this.formData[key].email.model,
              type: "email",
              length: 45
            }
          ];
        }
        let validate = validator.validate(validateParams);
        if(validate.result == "error"){
          this.formData[key].email.error = validate.message;
          return validate;
        }else{
          this.formData[key].email.error = "";
        }
      }
      return {result: 'ok'};
    },
    vAddField(){
      for(let key in this.formData){
        let validateParams;
        if(+this.setting.add_field_required === 1){
          validateParams = [
            {
              fieldName: this.setting.add_field_name,
              value: this.formData[key].add_field.model,
              type: "required",
              length: 450
            },
          ];
        }else{
          validateParams = [
            {
              fieldName: this.setting.add_field_name,
              value: this.formData[key].add_field.model,
              type: "string",
              length: 450
            },
          ];
        }
        let validate = validator.validate(validateParams);
        if(validate.result == "error"){
          this.formData[key].add_field.error = validate.message;
          return validate;
        }else{
          this.formData[key].add_field.error = "";
        }
      }
      return {result: 'ok'};
    },
    vComment(){
      for(let key in this.formData){
        let validate = validator.validate([{
          fieldName: "Комментарий",
          value: this.formData[key].comment.model,
          type: 'string',
          length: 450
        }]);
        if(validate.result == 'error'){
          this.formData[key].comment.error = validate.message;
          return validate;
        }else{
          this.formData[key].comment.error = "";
        }
      }
      return {result: 'ok'};
    },
    vNumber(){
      this.requestValidateNumber++;
      for(let key in this.formData){
        let validateEight = validator.validate([
          {
            fieldName: "Номер",
            value: this.formData[key].number.model,
            type: "eight_in_number",
          }
        ]);

        if(!this.ignoreEightInNumber && validateEight.result == 'error'){
          return validateEight;
        }

        let validate = validator.validate([
          {
            fieldName: "Номер",
            value: this.formData[key].number.model,
            type: "required",
            length: 20
          },
          {
            fieldName: "Номер",
            value: this.formData[key].number.model,
            type: "mask",
            mask: this.phoneMask
          }
        ]);

        if(validate.result == 'error'){
          return validate;
        }
      }
      return {result: 'ok'};
    },
    vPolitic(){
      if(!this.politic.model){
        let erMessage = 'Вы не подтвердили ознакомление с правилами онлайн-записи и политикой обработки персональных данных';
        this.politic.error = erMessage;
        return {result: 'error', message: erMessage};
      }else{
        this.politic.error = '';
        return {result: 'ok'};
      }
    },
    vNotifRule(){
      if(!this.notif_rule.model){
        let erMessage = 'Пожалуйста, примите соглашение на отсылку сервисных уведомлений о записи';
        this.notif_rule.error = erMessage;
        return {result: 'error', message: erMessage};
      }else{
        this.notif_rule.error = '';
        return {result: 'ok'};
      }
    },
    createEnroll() {
      if(this.validateData){
        return false;
      }

      // запишем данные клиента, для последующего подставления
      let saveClientObj = {};
      saveClientObj.surname = this.formData[0].surname.model;
      saveClientObj.name = this.formData[0].name.model;
      saveClientObj.lastname = this.formData[0].lastname.model;
      saveClientObj.number = this.formData[0].number.model;
      saveClientObj.email = this.formData[0].email.model.trim();
      localStorage.setItem('clientData', JSON.stringify(saveClientObj));

      let params = {clients: []};
      params.politic = this.politic.model;
      for(let key in this.formData){
        let client = {};
        client.surname = this.formData[key].surname.model;
        client.name = this.formData[key].name.model;
        client.lastname = this.formData[key].lastname.model;
        client.number = this.formData[key].number.model;
        client.email = this.formData[key].email.model.trim();
        client.comment = this.formData[key].comment.model;
        client.add_field = this.formData[key].add_field.model;
        client.remind_before_hour = this.formData[key].remindBefore.model;
        if(this.getParams.from){
          client.source_client_code = this.getParams.from;
        }else{
          client.source_client_code = '';
        }
        params.clients.push(client);
      }

      this.$emit("createEnroll", params);
    }
  },
  created() {
    
  }
};
</script>

<style scoped lang="less">
  .select-client--container{
    margin-top: 20px;
    padding: 15px 25px;
  }
  .s-client--politic{
    color: #f89191;
    text-decoration: underline;
    cursor: pointer;
  }
  .politic-checkbox-block{
    line-height: 1.5;
  }
</style>
