<template>
  <div></div>
</template>

<script>

export default {
  name: "SetManifest",
  components: {},
  data() {
    return {
      
    };
  },
  props: {
    short_name: String,
  },
  watch: {
    short_name: function(val){
      this.setManifest(val);
    }
  },
  
  methods: {
    setManifest(shortName){
      let manifestJSON =
      {
        "name": 'Запись онлайн',
        "short_name": shortName,
        "lang": "ru-RU",
        //"scope": document.location.href + '/',
        //"scope": "/",
        //"start_url": document.location.href + '/',
        //"id": document.location.href + '/',
        "start_url": "",
        //"id": 'http://192.168.0.106:8080/c/jQu',
        "dir": 'ltr',
        "display": "standalone",
        "assetsVersion": "3.8.2",
        "icons":[
                  {
                      "src":"https://online.enroller.ru/img/icons/android-icon-96x96.png",
                      "sizes":"96x96",
                      "type":"image/png"
                  },
                  {
                      "src":"https://online.enroller.ru/img/icons/android-chrome-192x192.png",
                      "sizes":"192x192",
                      "type":"image/png"
                  },
                  {
                      "src":"https://online.enroller.ru/img/icons/apple-icon-114x114.png",
                      "sizes":"114x114",
                      "type":"image/png"
                  },
                  {
                      "src":"https://online.enroller.ru/img/icons/apple-icon-120x120.png",
                      "sizes":"120x120",
                      "type":"image/png"
                  },
                  {
                      "src":"https://online.enroller.ru/img/icons/apple-icon-144x144.png",
                      "sizes":"144x144",
                      "type":"image/png"
                  },
              ],
        themeColor: "#5D5D5D",
        theme_color: "#5D5D5D",
        msTileColor: "#000",
        background_color: "#5D5D5D",
        backgroundColor: "#5D5D5D",
        manifestOptions: {
          background_color: "#5D5D5D",
          backgroundColor: "#5D5D5D",
          themeColor: "#5D5D5D",
          theme_color: "#5D5D5D",
        }
      };

      const stringManifest = JSON.stringify(manifestJSON);
      const blob = new Blob([stringManifest], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('link[rel="manifest"]').setAttribute('href', manifestURL);
      document.querySelector('link[rel="manifest"]').setAttribute('type', 'application/json');
      document.querySelector('meta[name="theme-color"]').setAttribute('content', '#38424e');
    }
  },
  created() {
    if(this.short_name != ''){
      this.setManifest(this.short_name);
    }
  }
};
</script>

<style scoped lang="less">

</style>
