<template>
  <div class="home">
    <div class="home--container">
      <new-enroll :modeComponent="computedScenario"></new-enroll>
      <div class="footer">
        <div class="text">Сервис записи онлайн <a class="text-link" href="https://enroller.ru" target="_blank">Enroller</a></div>
        <div class="copyright">© Enroller. 2018 - {{nowYear}}</div>
      </div>
      <preloader></preloader>
      <notificator></notificator>
      <yandex-metrika :user_metrika="all_configs.yandex_metrika"></yandex-metrika>
      <update-new-version-manual></update-new-version-manual>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

import NewEnroll from "@/components/NewEnroll";
import Preloader from "@/components/Preloader";
import axios from "axios";
import Notificator from "@/components/Notificator";
import YandexMetrika from "@/components/YandexMetrika";
import UpdateNewVersionManual from "../components/UpdateNewVersionManual";


export default {
  name: 'Company',
  data(){
    return {
      data: this.$route.params,
      routeQuery: this.$route.query,
      scenario: '',
      deferredPrompt: null
    }
  },
  props: {

  },
  computed: {
    ...mapState({
      all_configs: state => state.all_configs.configs,
    }),
    computedScenario(){
      let scenario = '';
      if(this.all_configs['scenario_online']){
        if(+this.all_configs['scenario_online'] === 1){
          scenario = 'mode';
        }else if(+this.all_configs['scenario_online'] === 2){
          scenario = 'staff';
        }else if(+this.all_configs['scenario_online'] === 3){
          scenario = 'services';
        }
      }
      return scenario;
    },
    nowYear(){
      let result = '';
      let nowDate = new Date();
      result = nowDate.getFullYear();
      return result;
    }
  },
  methods: {
    getConfigs(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("company", this.data.company);
      axios
          .post(`/online/get-all-configs`, formData)
          .then(resp => {
            let conf = resp.data;
            if(conf.autoselect_one_service == 1 && conf.autoselect_one_staff == 0){
              conf.scenario_online = 3;
            }else if(conf.autoselect_one_service == 0 && conf.autoselect_one_staff == 1){
              conf.scenario_online = 2;
            }else if(conf.autoselect_one_service == 1 && conf.autoselect_one_staff == 1){
              conf.scenario_online = 3;
            }
            this.$store.dispatch("allConfigsAdd", conf);
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            // Удалим параметр. Т.к скорее всего ссылка была неверная
            localStorage.removeItem('company_id');
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
  },
  components: {
    UpdateNewVersionManual,
    YandexMetrika,
    Notificator,
    Preloader,
    NewEnroll
  },
  created() {
    localStorage.setItem('company_id', this.data.company);
    this.getConfigs();
  }
}
</script>

<style scoped lang="less">
  .home{
    background-color: #F0F4F9;
    padding-bottom: 90px;
    min-height: 100vh;
    padding-top: 5px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .home--container{
    width: 600px;
    margin: 0 auto;
    //border: 1px solid red;
  }
  @media (max-width: 600px) {
    .home--container{
      width: 100%;
    }
  }
</style>
