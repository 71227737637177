const version = {
  state: {
    version: null,
    nextUpdate: 0,
  },
  mutations: {
    ADD_VERSION(state, version) {
      state.version = version;
    },
  },
  actions: {
    addVersion({ commit, state }, version) {
      let nowDate = new Date();
      state.nextUpdate = nowDate.getTime() + 20 *(60 *1000);
      commit("ADD_VERSION", version);
    },
  }
};

export default version;
