<template>
  <div class="select-calendar--container no-select shadow-card">
    <div v-if="showEmptyError" class="notif-box danger mrg-b-10">
      Время для записи не найдено. Все время уже занято или у сотрудника нет расписания на
      ближайшее время<br />
    </div>
    <div class="select-calendar--title">Выбрать дату:</div>
    <div class="select-calendar--calendar">
      <div class="select-calendar--top-bar">
        <div @click="prevMonth" class="left">
          <f-awesome icon="arrow-left"></f-awesome>
        </div>
        <div class="month">
          <b>{{ nowMonth }}</b
          >, {{ nowYear }}
        </div>
        <div @click="nextMonth" class="right">
          <f-awesome icon="arrow-right"></f-awesome>
        </div>
      </div>
      <div class="select-calendar--content">
        <div class="select-calendar--week select-calendar--week-title">
          <div>Пн</div>
          <div>Вт</div>
          <div>Ср</div>
          <div>Чт</div>
          <div>Пт</div>
          <div>Сб</div>
          <div>Вс</div>
        </div>
        <div
          v-for="(week, key) in calendar"
          :key="key"
          class="select-calendar--week select-calendar--week-items"
        >
          <div v-for="(day, index) in week" :key="index">
            <div v-if="day.day !== 0">
              <div
                @click="selectDay(day.day)"
                class="cell"
                v-bind:class="{
                  today: day.today,
                  disabled: day.active === false,
                  checked: day.checked
                }"
              >
                {{ day.day }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="select-calendar--hint">
      <div><span></span> Доступно для записи</div>
      <div><span></span> Недоступно для записи</div>
    </div>
  </div>
</template>

<script>
import Formatter from "@/components/libraries/Formatter";

const formatter = new Formatter();

// import axios from "axios";

//import axios from "axios";

import CalendarMonthData from "@/components/libraries/CalendarMonthData";

export default {
  name: "SelectCalendar",
  components: {},
  data() {
    return {
      nowDate: new Date(),
      nowMonth: "",
      nowYear: "",
      calendar: [],
      activeData: [],
      selectedDate: { day: 0, month: 0, year: 0 },
      showEmptyError: false
    };
  },
  props: {
    active: Array,
    reset: Number
  },
  watch: {
    active: function(val) {
      this.activeData = val;
      if (val.length) {
        this.nowDate = formatter.phpDateToJSObject(val[0]);
        this.showEmptyError = false;
      } else {
        this.nowDate = new Date();
        this.showEmptyError = true;
      }
      this.selectedDate.day = 0;
      this.selectedDate.month = 0;
      this.selectedDate.year = 0;
      this.renderCalendar();
    }
  },
  computed: {},
  methods: {
    renderCalendar() {
      let monthData = new CalendarMonthData(
        this.nowDate.getFullYear(),
        this.nowDate.getMonth()
      );
      let resultCalendar = monthData.getData();
      this.dateFrom = formatter.dateToPhpFormat(monthData.getDateFrom());
      this.dateTo = formatter.dateToPhpFormat(monthData.getDateTo());
      this.nowMonth = monthData.getMonthName();
      this.nowYear = monthData.getYear();

      for (let key in resultCalendar) {
        for (let week in resultCalendar[key]) {
          if (
            +resultCalendar[key][week]["day"] === this.selectedDate.day &&
            +resultCalendar[key][week]["month"] === this.selectedDate.month &&
            +resultCalendar[key][week]["year"] === this.selectedDate.year
          ) {
            resultCalendar[key][week].checked = true;
          } else {
            resultCalendar[key][week].checked = false;
          }

          let activeCounter = false;
          for (let active in this.activeData) {
            let activeData = formatter.phpDateToJSObject(this.activeData[active]);
            if (
              +activeData.getDate() === +resultCalendar[key][week]["day"] &&
              +activeData.getMonth() === +resultCalendar[key][week]["month"] &&
              +activeData.getFullYear() === +resultCalendar[key][week]["year"]
            ) {
              activeCounter = true;
            }
          }
          if (activeCounter) {
            resultCalendar[key][week].active = true;
          } else {
            resultCalendar[key][week].active = false;
          }
        }
      }

      this.calendar = resultCalendar;
    },
    selectDay(day) {
      // Сначала проверим, активен ли день
      for (let key in this.calendar) {
        for (let week in this.calendar[key]) {
          if (+this.calendar[key][week].day === +day) {
            if (!this.calendar[key][week].active) {
              this.$store.dispatch("addNotification", {
                text:
                  "Данный день неактивен (у сотрудника нет расписания или все время уже занято)",
                time: 6,
                color: "danger"
              });
              return false;
            }
          }
        }
      }
      for (let key in this.calendar) {
        for (let week in this.calendar[key]) {
          if (+this.calendar[key][week].day === +day) {
            if (this.calendar[key][week].active) {
              this.$set(this.calendar[key][week], "checked", true);
              this.selectedDate.day = this.calendar[key][week].day;
              this.selectedDate.month = this.calendar[key][week].month;
              this.selectedDate.year = this.calendar[key][week].year;
            }
          } else {
            this.$set(this.calendar[key][week], "checked", false);
          }
        }
      }
      this.emitSelect();
    },
    emitSelect() {
      for (let key in this.calendar) {
        for (let week in this.calendar[key]) {
          if (this.calendar[key][week].checked) {
            this.$emit("selectDate", this.calendar[key][week]);
            return false;
          }
        }
      }
      this.$emit("selectDate", false);
    },
    prevMonth() {
      this.nowDate.setDate(1);
      this.nowDate.setMonth(this.nowDate.getMonth() - 1);
      this.renderCalendar();
    },
    nextMonth() {
      this.nowDate.setDate(1);
      this.nowDate.setMonth(this.nowDate.getMonth() + 1);
      this.renderCalendar();
    }
  },
  created() {}
};
</script>

<style scoped lang="less">
.select-calendar--container{
  padding: 15px 0;
  margin-top: 20px;
}
.select-calendar--top-bar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;

  .left {
    cursor: pointer;
    font-size: 20px;
    text-align: right;
    width: 30%;
  }
  .month {
    color: #4f4f4f;
    font-size: 18px;
    text-align: center;
    width: 40%;
  }
  .right {
    cursor: pointer;
    text-align: left;
    font-size: 20px;
    width: 30%;
  }
}
.select-calendar--content {
  margin-top: 20px;
}

.select-calendar--week {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;

  & > div {
    text-align: center;
    width: 14%;
  }
}
.select-calendar--week-title {
  color: #828282;
  font-size: 12px;
  border-bottom: 1px solid #e2e7ed;
  padding-bottom: 4px;
}
.select-calendar--week-items {
  cursor: pointer;
  margin-top: 10px;
  /*margin-bottom: 10px;*/

  .cell {
    width: 24px;
    height: 24px;
    margin: 0 auto;
    color: #333;
    border: 1px solid transparent;
    text-align: center;
    line-height: 24px;
    font-size: 15px;

    &.today {
      border-bottom: 1px solid #f97979;
    }
    &.disabled {
      color: #bdbdbd;
    }
    &.checked {
      border: 1px solid #f97979;
      border-radius: 14px;
      font-weight: 700;
    }
  }
}
.select-calendar--hint {
  margin-top: 25px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  flex-direction: row;

  & > div {
    width: 47%;
    color: #828282;
    text-align: center;

    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      border-radius: 10px;
    }
  }
  & > div:nth-of-type(1) {
    span {
      background-color: #333333;
    }
  }
  & > div:nth-of-type(2) {
    span {
      background-color: #c4c4c4;
    }
  }
}
.select-calendar--title {
  text-align: center;
  color: #4f4f4f;
  font-size: 16px;
  margin-bottom: 30px;
}
</style>
