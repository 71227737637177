<template>
  <div class="done-enroll--container">
    <div><img :src='metricaImgSrc' style="position:absolute; left:-9999px;" alt="" /></div>
  </div>
</template>

<script>

import { mapState } from 'vuex';
import YandexMetrika from "@/components/libraries/YandexMetrika";

const yandex = new YandexMetrika();

export default {
  name: "YandexMetrika",
  components: {},
  data() {
    return {
      user_metrika_counter: 0, // счетчик метрики пользователя
      metrica_number: 70031971 // счетчик мой
    };
  },
  props: {
    user_metrika: String
  },
  watch: {
    user_metrika: function(val){
      if(val != null || val != ''){
        this.user_metrika_counter = +val;
      }
    },
    user_metrika_counter: function(val){
      yandex.setCounter(val);
    },
    targets: function(val){
      if(val.length){
        for(let key in val){
          this.reachGoalMetric(this.metrica_number, val[key].targetName);
          if(this.user_metrika_counter > 0){
            this.reachGoalMetric(this.user_metrika_counter, val[key].targetName);
          }
        }
      }
    }
  },
  computed: {
    ...mapState({
      targets: state => state.yametric_target.targets,
    }),
    metricaImgSrc(){
      let result = 'https://mc.yandex.ru/watch/' +this.metrica_number;
      return result;
    }
  },
  methods: {
    reachGoalMetric(targetNumber, targetName){
      yandex.reachGoal(targetNumber, targetName);
      this.$store.dispatch("flushYaMetricTargets");
    }
  },
  created() {
    yandex.setCounter(this.metrica_number);
  }
};
</script>

<style scoped lang="less">

</style>
