var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"enroll-labels--content no-select"},[(_vm.data.date)?_c('div',{staticClass:"enroll-labels--container"},[_c('div',{staticClass:"enroll-labels--list"},[_c('div',{staticClass:"date"},[_c('div',{on:{"click":_vm.prevDay}},[_c('f-awesome',{attrs:{"icon":"arrow-left"}})],1),_c('div',[_vm._v(_vm._s(_vm.datePhpToRus(_vm.date)))]),_c('div',{on:{"click":_vm.nextDay}},[_c('f-awesome',{attrs:{"icon":"arrow-right"}})],1)]),_c('div',{staticClass:"schedule-info"},[(_vm.data.result == 'ok')?_c('div',[_vm._v(" Рабочий день с "+_vm._s(_vm.minuteToTime(_vm.schedule.time_from))+" до "+_vm._s(_vm.minuteToTime(_vm.schedule.time_to))+" ")]):_vm._e(),(_vm.data.result == 'error')?_c('div',[_vm._v(" "+_vm._s(_vm.data.text)+" ")]):_vm._e()]),(_vm.data.result == 'ok')?_c('div',{staticClass:"labels"},_vm._l((_vm.labels),function(item,index){return _c('div',{key:index,staticClass:"items"},[(item.type == 'free')?_c('div',{staticClass:"free item",class:{ progress: item.progress === true }},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"text"},[_vm._v(" Окно "+_vm._s(_vm.minuteToTime(item.time_from))+" - "+_vm._s(_vm.minuteToTime(item.time_to))+" "),_c('div',{staticClass:"duration_window"},[_vm._v(" ("+_vm._s(_vm.getDurationWindow(item.time_from, item.time_to))+") ")])])])]):_vm._e(),(item.type == 'break')?_c('div',{staticClass:"break item"},[_vm._v(" Перерыв "+_vm._s(_vm.minuteToTime(item.time_from))+" - "+_vm._s(_vm.minuteToTime(item.time_to))+" ")]):_vm._e(),(item.type == 'enroll')?_c('div',{staticClass:"enroll item",class:{
            new: item.confirm == 0,
            confirm: item.confirm == 1,
            done: item.confirm == 2,
            canceled: item.confirm == 3,
            progress: item.progress === true
          }},[_c('div',{staticClass:"head"},[_c('div',{staticClass:"action"}),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.minuteToTime(item.time_from))+" - "+_vm._s(_vm.minuteToTime(item.time_to))+" ")])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],staticClass:"more"},[(+item.is_group === 0)?_c('div',{staticClass:"client_name"},[_vm._v(" "+_vm._s(item.enrollClients[0].clients.name)+" "+_vm._s(item.enrollClients[0].clients.surname)+" "),(item.enrollClients[0].clients.sale)?_c('span',[_vm._v("(скидка "+_vm._s(item.enrollClients[0].clients.sale)+"%) ")]):_vm._e(),(+item.enrollClients[0].clients.is_unwanted === 1)?_c('span',[_c('b',[_vm._v("(нежелательный клиент!)")])]):_vm._e()]):_vm._e(),(+item.is_group === 1)?_c('div',{staticClass:"client_name"},[_c('span',{staticClass:"text-bold"},[_vm._v(_vm._s(_vm.calculateActiveGroupCount(item.enrollClients)))]),_vm._v(" клиента (-ов) в групповой записи ")]):_vm._e(),(item.enrollPosition && item.enrollPosition[0].service_name)?_c('div',{staticClass:"client_services"},[_c('b',[_vm._v("Услуги:")]),_vm._l((item.enrollPosition),function(service){return _c('span',{key:service.id},[_vm._v(" ("),_c('b',[_vm._v(_vm._s(service.category_name))]),_vm._v(") "+_vm._s(service.service_name)+"; ")])})],2):_vm._e(),(item.comments)?_c('div',{staticClass:"comment_company"},[_c('b',[_vm._v("Комментарий компании:")]),_vm._v(" "+_vm._s(item.comments.comment_company)+" ")]):_vm._e()])]):_vm._e()])}),0):_vm._e()])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }