class CalendarMonthData {
  constructor(year, month) {
    this.weekdayNumber = [6, 0, 1, 2, 3, 4, 5];
    this.monthName = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь"
    ];
    this.nowDate = new Date(year, month, 1);
    this.dateFrom = "";
    this.dateTo = "";
  }

  getData() {
    let date = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1);
    let dayInMoth = 0;
    this.dateFrom = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate()
    );

    let todayDate = new Date();

    // Узнаем кол-во дней в месяце
    for (let i = 1; i <= 33; i++) {
      if (date.getDate() != i) {
        date.setDate(date.getDate() - 1);
        dayInMoth = date.getDate();
        this.dateTo = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate()
        );
        break;
      }
      date.setDate(date.getDate() + 1);
    }

    // Сделаем список дней в месяце
    date = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), 1);
    let calendarList = [];
    for (let i = 1; i <= dayInMoth; i++) {
      date = new Date(this.nowDate.getFullYear(), this.nowDate.getMonth(), i);
      let dayObj = {};
      dayObj.day = date.getDate();
      dayObj.month = date.getMonth();
      dayObj.year = date.getFullYear();
      dayObj.weekday = this.weekdayNumber[date.getDay()];
      calendarList.push(dayObj);
      // date.setDate(date.getDate() + 1);
    }

    let calendar = [];
    let count = 0;
    let breakMonth = false;
    for (let i = 0; i <= 7; i++) {
      let week = [];
      if (typeof calendarList[count] !== "undefined") {
        for (let z = 0; z < 7; z++) {
          let day = {};

          if (typeof calendarList[count] !== "undefined") {
            if (calendarList[count].weekday === z) {
              day.day = calendarList[count].day;
              day.month = calendarList[count].month;
              day.year = calendarList[count].year;
              if (
                +todayDate.getDate() === +calendarList[count].day &&
                +todayDate.getMonth() === +calendarList[count].month &&
                +todayDate.getFullYear() === +calendarList[count].year
              ) {
                day.today = true;
              } else {
                day.today = false;
              }

              count++;
            } else {
              day.day = 0;
              day.month = 0;
              day.year = 0;
            }
          } else {
            breakMonth = true; // Отмечаем, что дни в месяце закончились, чтобы остановить цикл
            day.day = 0;
            day.month = 0;
            day.year = 0;
          }
          week.push(day);
        }
      }
      if (week.length) {
        calendar.push(week);
      }
      if (breakMonth) break;
    }
    return calendar;
  }

  getDateFrom() {
    return this.dateFrom;
  }

  getDateTo() {
    return this.dateTo;
  }

  getMonthName() {
    return this.monthName[this.nowDate.getMonth()];
  }

  getYear() {
    return this.nowDate.getFullYear();
  }
}
export default CalendarMonthData;
