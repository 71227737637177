<template>
  <div class="common">
    <div class="common--back-block">
      <div class="title shadow-card">Выберите филиал</div>
      
      <div v-if="issetCoordinats" class="mrg-t-10 text-center">
        <ul class="tabs-container-strong">
          <li
            @click="clickTab(item)"
            v-bind:class="{ active: item.isActive }"
            v-for="item in tabs"
            :key="item.id"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
      
      <div v-if="activeTabIndex === 2" class="mrg-t-10">
        <l-map style="height: 50vh" :zoom="zoom" :center="centerCoordinat">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker v-for="company in markerList" :key="company.id" :lat-lng="[company.lattitude, company.longtitude]" >
          <l-icon>
            <img width="65px;" src="../assets/img/map_marker_red.png">
          </l-icon>
          <l-popup>
            <div class="text-center">
              <div>{{company.address}}</div>
              <div v-if="+company.company_status === 1" class="mrg-t-10 text-orange">
                {{company.closed_description}}
              </div>
              <router-link tag="a" style="color: #fff;" class="btn btn-sm btn-info mrg-t-10" :to="generateLinkCompany(company.id)">
                Перейти к записи
              </router-link>
            </div>
          </l-popup>
        </l-marker>
        </l-map>
      </div>

      <div v-show="issetBackUrl" class="newEnroll__actions_fix">
        <div @click="returnToUrl" class="btn btn-sm btn-outline-danger">
          <f-awesome icon="times"></f-awesome> Закрыть
        </div>
      </div>

      <div v-if="activeTabIndex === 1" class="common--container">
        <a class="common-item shadow-card" v-for="item in data" :key="item.id"  :href="generateLinkCompany(item.id)">
          <div class="img">
            <img :src="item.image">
          </div>
          <div class="info">
            <div class="name">{{item.name}}</div>
            <div class="city mrg-t-10">{{item.city}}</div>
            <div class="address">{{item.address}}</div>
            <div class="number mrg-t-10">{{item.number}}</div>
            <div v-if="+item.company_status === 1" class="mrg-t-10 text-orange">
              {{item.closed_description}}
            </div>
          </div>
        </a>
      </div>
      <div class="footer">
        <div class="text">Сервис записи онлайн <a class="text-link" href="https://enroller.ru" target="_blank">Enroller</a></div>
        <div class="copyright">© Enroller. 2018 - {{nowYear}}</div>
      </div>
      <preloader></preloader>
      <notificator></notificator>
      <update-new-version-manual></update-new-version-manual>
    </div>
  </div>
</template>

<script>

import Preloader from "@/components/Preloader";
// import axios from "axios";
import Notificator from "@/components/Notificator";
import axios from "axios";
import {mapState} from 'vuex';
import UpdateNewVersionManual from "../components/UpdateNewVersionManual";

export default {
  name: 'Common',
  data(){
    return {
      clientWidth: document.documentElement.clientWidth,
      user_id: this.$route.params,
      getParams: this.$route.query,
      data: [],

      tabs: [
        {
          id: 1,
          name: "Списком",
          isActive: true
        },
        {
          id: 2,
          name: "На карте",
          isActive: false
        }
      ],

      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 10
    }
  },
  props: {

  },
  computed: {
    ...mapState({
      backLink: state => state.back_common.backLink
    }),
    activeTabIndex() {
      let result = 0;
      this.tabs.forEach(function(item) {
        if (item.isActive) {
          result = item.id;
        }
      });
      return result;
    },
    markerList(){
      let result = [];
      for(let key in this.data){
        if(this.data[key].lattitude && this.data[key].lattitude.length > 0){
          result[result.length] = this.data[key];
        }
      }
      return result;
    },
    centerCoordinat(){
      let result = [55.774894, 37.593665];
      for(let key in this.data){
        if(this.data[key].lattitude && this.data[key].lattitude.length > 0){
          result = [this.data[key].lattitude, this.data[key].longtitude];
        }
      }
      return result;
    },
    issetCoordinats(){
      let result = false;
      for(let key in this.data){
        if(this.data[key].lattitude && this.data[key].lattitude.length > 0){
          result = true;
        }
      }
      return result;
    },
    nowYear(){
      let result = '';
      let nowDate = new Date();
      result = nowDate.getFullYear();
      return result;
    },
    issetBackUrl(){
      let result = false;
      if(typeof this.getParams.back_url !== 'undefined'){
        result = true;
      }
      return result;
    }
  },
  methods: {
    clickTab(item) {
      for (let key in this.tabs) {
        if (this.tabs[key].id === item.id) {
          this.tabs[key].isActive = true;
        } else {
          this.tabs[key].isActive = false;
        }
      }
    },
    generateLinkCompany(id){
      let result = '/c/' +id;
      if(this.issetBackUrl){
        result += '?back_url=' +this.getParams.back_url +'&back_link=' +this.user_id.user;
      }else{
        result += '?back_link=' +this.user_id.user;
      }
      return result;
    },
    getCompanies(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("user_id", this.user_id.user);
      axios
          .post(`/online/get-companies-by-user`, formData)
          .then(resp => {
            this.data = resp.data;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    },
    returnToUrl(){
      location.href = this.getParams.back_url;
    }

  },
  components: {
    UpdateNewVersionManual,
    Notificator,
    Preloader,
  },
  created() {
    this.getCompanies();
  }
}
</script>

<style scoped lang="less">
.common{
  padding-top: 1px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 55px;
  min-height: 100vh;
  background-color: #F0F4F9;

  .title{
    margin-top: 10px;
    padding: 15px 5px;
    background-color: #fff;
    text-align: center;
    color: #4f4f4f;
    font-size: 16px;
  }
}
.common--back-block{
  width: 600px;
  margin: 0 auto;
}
.common--container{
  min-height: 75vh;
  //padding-top: 1px;
}
.common-item{
  margin-top: 20px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: row;
  background-color: #fff;
  padding: 10px 0 15px 0;

  &>div:nth-of-type(1){
    width: 35%;
    text-align: center;
    //border: 1px solid red;
  }
  &>div:nth-of-type(2){
    width: 65%;
    //border: 1px solid red;
  }

  .info{
    padding: 8px;
    box-sizing: border-box;
    color: #4F4F4F;

    .name{
      font-size: 16px;
      font-weight: 700;
    }
    .address{
      font-size: 14px;
    }
  }

  .img{
    position: relative;
    img{
      position: absolute;
      display: inline-block;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 85px;
    }
  }
}
.newEnroll__actions {
  margin-top: 20px;
  background-color: #fff;
  padding: 15px 8px;
  box-sizing: border-box;
}
.newEnroll__actions_fix{
  text-align: center;
  padding: 15px 8px;
  box-sizing: border-box;
  width:inherit;
  position: fixed;
  bottom: 0;
  background-color: #fff;
  box-shadow: -1px -3px 24px 0px rgba(0,0,0,0.75);
}
@media (max-width: 600px) {
  .common--back-block{
    width: 100%;
  }
}
</style>
