<template>
  <div class="unsubscribe">
    <div class="unsubscribe--container">
      <div class="smile">
        <f-awesome icon="sad-tear"></f-awesome>
      </div>
      <div class="result-text">
        <div v-if="unsubscribeResult == 1">
          Вы успешно отписались! Больше мы вас не побеспокоим. Если вновь захотите получать сообщения, то напишите нам
          на <b>info@enroller.ru</b>
        </div>
        <div v-if="unsubscribeResult == 2">
          Упс! Что-то пошло не так. Пожалуйста, напишите нам на info@enroller.ru, что хотите отказаться от рассылки.
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="text">Сервис записи онлайн <a class="text-link" href="https://enroller.ru" target="_blank">Enroller</a></div>
      <div class="copyright">© Enroller. 2018 - {{nowYear}}</div>
    </div>
  </div>
</template>

<script>

import axios from "axios";

export default {
  name: 'Unsubscribe',
  data(){
    return {
      data: this.$route.params,
      routeQuery: this.$route.query,
      unsubscribeResult: 0
    }
  },
  props: {

  },
  computed: {
    nowYear(){
      let result = '';
      let nowDate = new Date();
      result = nowDate.getFullYear();
      return result;
    }
  },
  methods: {

  },
  components: {

  },
  created() {
    this.$store.dispatch("startPreloader");
    let formData = new FormData();
    formData.append("hash", this.data.hash);
    axios
        .post(`/online/unsubscribe`, formData)
        .then(() => {
          this.unsubscribeResult = 1;
          this.$store.dispatch("stopPreloader");
        })
        .catch(() => {
          this.unsubscribeResult = 2;
          this.$store.dispatch("stopPreloader");
        });
  }
}
</script>

<style scoped lang="less">
  .unsubscribe{
    background-color: #F0F4F9;
    padding-bottom: 55px;
    min-height: 100vh;
  }
  .unsubscribe--container{
    width: 500px;
    min-height: 65vh;
    padding-top: 70px;
    margin: 0 auto 60px auto;
    text-align: center;

    .smile{
      font-size: 100px;
    }
    .result-text{
      font-size: 18px;
    }
  }
  @media (max-width: 600px) {
    .unsubscribe--container{
      width: 100%;
    }
  }
</style>
