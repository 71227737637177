<template>
  <div class="employment-table--content cursor-pointer">
    <div class="j-week--labels-container">
      
      <div class="journal--bar-dates mrg-t-10">
        <div class="left" @click="prevWeek">
          <f-awesome icon="arrow-left"></f-awesome>
        </div>
        <div class="date">
          <b>{{ weekString }}</b>
        </div>
        <div class="right" @click="nextWeek">
          <f-awesome icon="arrow-right"></f-awesome>
        </div>
      </div>

      <div class="j-week--labels-overflow mrg-t-20">
        <div class="day" v-for="(item, index) in calendar" :key="index">
          <div class="date text-center" :class="{'today': item.isToday === true}">
            {{datePhpToRus(item.date)}}, {{item.weekday}}
          </div>
          <div class="no-schedule" v-if="Object.keys(item.labels).length === 0">
            Нет расписания
          </div>
          <div class="staffs">
            <div class="staff" v-for="(staff, index) in item.labels" :key="index">
              
              <div v-if="staff.result == 'error'" class="error">{{staff.text}}</div>
              <div v-if="staff.result == 'ok'" class="schedule">
                <div class="schedule-item" v-for="(sch, index) in staff.response" :key="index">
                  <div 
                    class="item-free" 
                    v-if="sch.type == 'free'"
                    v-bind:class="{ progress: sch.progress === true }"
                  >
                    <div>
                      <span>Окно  </span> 
                      <span>{{minuteToTime(sch.time_from)}} - {{minuteToTime(sch.time_to)}}</span> <br>
                      <span class="time-window">({{getDiffTime(sch.time_from, sch.time_to)}})</span>
                    </div>
                    <div class="action">
          
                    </div>
                  </div>
                  <div 
                    v-if="sch.type == 'enroll'" 
                    class="item-enroll" 
                    :class="{
                      'status-new': sch.confirm == 0, 
                      'status-confirm': sch.confirm == 1, 
                      'status-done': sch.confirm == 2,
                      'status-canceled': sch.confirm == 3,
                      'progress': sch.progress === true
                    }"
                  >
                    <div class="time">
                      <span><b>{{minuteToTime(sch.time_from)}} - {{minuteToTime(sch.time_to)}}</b></span> <br>
                      <span class="time-window">({{getDiffTime(sch.time_from, sch.time_to)}})</span>
                    </div>
                    <div class="more mrg-t-10">
                      
                      <div v-if="+sch.is_group === 0" class="client_name">
                        {{ sch.enrollClients[0].clients.name }} {{ sch.enrollClients[0].clients.surname }}
                        <span v-if="sch.enrollClients[0].clients.sale">(скидка {{sch.enrollClients[0].clients.sale}}%) </span>
                        <span v-if="+sch.enrollClients[0].clients.is_unwanted === 1"><b>(нежелательный клиент!)</b></span>
                      </div>
                      <div v-if="+sch.is_group === 1" class="client_name">
                        <span class="text-bold">{{ calculateActiveGroupCount(sch.enrollClients) }}</span> клиента (-ов) в групповой записи
                      </div>
                      <div 
                        v-if="sch.enrollPosition && sch.enrollPosition[0].service_name" 
                        class="client_services mrg-t-10"
                      >
                        <span 
                          v-for="service in sch.enrollPosition" 
                          :key="service.id"
                        >
                          <b>{{ service.service_name }};</b>
                        </span>
                      </div>
                      <div 
                        v-if="sch.comments && sch.comments.comment_company" 
                        class="company_comment mrg-t-5"
                      >
                        Комментарий компании: {{sch.comments?sch.comments.comment_company:'-'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

import axios from "axios";
import Formatter from "../libraries/Formatter";

const formatter = new Formatter();

export default {
  name: "EnrollLabelsWeek",
  components: {},
  data() {
    return {
      data: [],
      nowDate: new Date(),
      weekdayName: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
      weekdayNumber: [6, 0, 1, 2, 3, 4, 5],
      dateFrom: null,
      dateTo: null,
      calendar: [],
      showLabels: false,
      fastNewDefaultDate: '1.1.1970',
      wantTime: 0,
      selectedEnrollId: null,
      fastNewMode: "new",
      selectedStaffId: 0,
      params: this.$route.params,
    };
  },
  props: {
    staff: Array
  },
  watch: {
    
  },
  computed: {
    weekString() {
      let result = "";
      if (this.dateFrom) {
        let dateFrom = formatter.phpDateToJSObject(this.dateFrom);
        let dateTo = formatter.phpDateToJSObject(this.dateTo);
        let monthFrom = dateFrom.getMonth() + 1;
        let monthTo = dateTo.getMonth() + 1;
        result = `${dateFrom.getDate()}.${
          monthFrom < 10 ? "0" + monthFrom : monthFrom
        } - ${dateTo.getDate()}.${monthTo < 10 ? "0" + monthTo : monthTo}`;
      }
      return result;
    },
  },
  methods: {
    calculateActiveGroupCount(data){
      let result = 0;
      for(let key in data){
        if(+data[key].is_canceled === 0){
            if(data[key].clients){
              result++;
            }
          }
      }
      return result;
    },
    phoneNumberToMask(number){
      return formatter.phoneNumberToMask(number);
    },
    getDiffTime(time_from, time_to) {
      let result = formatter.minuteSplitHumanHourMinute(time_to - time_from);
      return result;
    },
    minuteToTime(minutes) {
      return formatter.minuteToTime(minutes);
    },
    getStaffName(staffId){
      let result = '-';
      for(let key in this.staff){
        if(+staffId === +this.staff[key].id){
          result = this.staff[key].name;
        }
      }
      if(result.length > 25){
        return result.slice(0, 25) +'...';
      }else{
        return result.slice(0, 25);
      }
    },
    datePhpToRus(val){
      return formatter.phpDateToRus(val);
    },
    prevWeek() {
      this.nowDate.setDate(this.nowDate.getDate() - 7);
      this.renderWeek();
    },
    nextWeek() {
      this.nowDate.setDate(this.nowDate.getDate() + 7);
      this.renderWeek();
    },
    renderWeek() {
      let nowDay = this.weekdayNumber[this.nowDate.getDay()];
      this.nowDate.setDate(this.nowDate.getDate() - nowDay);

      let date = new Date(
        this.nowDate.getFullYear(),
        this.nowDate.getMonth(),
        this.nowDate.getDate()
      );
      let today = new Date();
      this.dateFrom = formatter.dateToPhpFormat(date);

      this.calendar = [];
      for (let i = 0; i <= 6; i++) {
        let dayObj = {};
        dayObj.date = formatter.jsObjectToPhp(date);
        dayObj.isToday = formatter.isIdenticalDates(formatter.jsObjectToPhp(date), formatter.jsObjectToPhp(today));
        dayObj.weekday = this.weekdayName[date.getDay()];
        dayObj.labels = {};
        this.calendar.push(dayObj);
        if (i === 6) {
          this.dateTo = formatter.dateToPhpFormat(date);
        }
        date.setDate(date.getDate() + 1);
      }
      this.calendar = this.calendar.reverse();
      this.getLabels();
    },
    getLabels(){
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("company_id", this.params.companyId);
      formData.append("hash", this.params.hash);
      formData.append("dateFrom", this.dateFrom);
      formData.append("dateTo", this.dateTo);
      
      axios
          .post(`/online/get-labels-week-for-staff`, formData)
          .then(resp => {
            this.lastUpdate = new Date();
            for (let key in resp.data) {
              for (let cal in this.calendar) {
                if (key === this.calendar[cal].date) {
                  for(let keyStaff in resp.data[key]){
                    this.$set(this.calendar[cal]['labels'], keyStaff, resp.data[key][keyStaff]);
                  }
                }
              }
            }
            for(let cal in this.calendar){
              for(let staff in this.calendar[cal].labels){
                if(this.calendar[cal].labels[staff].result == 'ok'){
                  for(let sch in this.calendar[cal].labels[staff].response){
                    this.calendar[cal].labels[staff].response[sch].progress = false;
                  }
                }
              }
            }
            this.showLabels = true;
            this.$store.dispatch("stopPreloader");
          })
          .catch(err => {
            this.$store.dispatch("stopPreloader");
            this.errorText = err.response.data.message;
            this.$store.dispatch("addNotification", {
              text: err.response.data.message,
              time: 6,
              color: "danger"
            });
          });
    }
  },
  created() {
    this.renderWeek();
  }
};
</script>

<style scoped lang="less">
@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  100% {
    box-shadow: 0px 0px 24px 0px rgba(65, 162, 252, 1);
  }
}
.journal--bar-dates {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  flex-direction: row;
  text-align: center;

  & > div {
    cursor: pointer;
    color: #4f4f4f;
  }

  & > div:nth-of-type(1) {
    width: 50px;
    text-align: right;
    font-size: 17px;
  }
  & > div:nth-of-type(2) {
    width: 50%;
    font-size: 18px;
    text-align: center;
  }
  & > div:nth-of-type(3) {
    width: 50px;
    text-align: left;
    font-size: 17px;
  }
}
.j-week--labels-container {
  box-sizing: border-box;
  
  .j-week--labels-overflow {
    overflow: auto;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: row;
    max-height: 70vh;
    direction: rtl;

    .day {
      text-align: center;
      min-width: 130px;
      margin-right: 5px;

      .date{
        background-color: #f0f4f9;
        font-size: 13px;
        //font-weight: 700;
        padding: 8px 5px;
        box-sizing: border-box;
        margin-bottom: 5px;
        border-bottom: 2px solid transparent;
        direction: ltr;

        &.today{
          border-bottom: 2px solid #F97979;
        }
      }

      .no-schedule{
        font-size: 12px;
        color: gray;
      }
      .staffs{
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;
        flex-direction: row;
        padding-bottom: 20px;
        direction: ltr;

        .staff{
          width: 150px;
          margin-right: 3px;
          margin-left: 3px;

          .name{
            font-weight: 700;
            text-overflow: ellipsis;
            overflow: hidden;
            height: 55px;
            font-size: 13px;
            padding: 6px;
            box-sizing: border-box;
            background-color: #f5f7fa;
            box-shadow: 3px 3px 6px 0px rgba(0, 0, 0, 0.15);
          }
          .error{
            border: 1px solid gray;
            padding: 4px;
            font-size: 12px;
          }
          .schedule{
            font-size: 12px;
            .schedule-item{
              margin-top: 5px;
              .item-free{
                padding: 7px 5px;
                border-radius: 10px;
                background-color: #f0f4f9;

                .time-window{
                  font-size: 11px;
                }

                &.progress {
                  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
                  animation: pulse 1s infinite;
                }

                .action{
                  margin-top: 4px;

                  .new-enroll{
                    color: #333;
                    font-size: 11px;
                  }
                }
              }
              .item-enroll{
                padding: 7px 5px;
                border-radius: 10px;

                .time-window{
                  font-size: 11px;
                }

                &.status-canceled{
                  color: #fff;
                }

                &.progress {
                  box-shadow: 0 0 0 rgba(204, 169, 44, 0.4);
                  animation: pulse 1s infinite;
                }
                
              }
            }
          }
        }
      }
    }
  }
}
</style>
