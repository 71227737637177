import Vue from "vue";
import Vuex from "vuex";

import preloader from "./modules/preloader";
import notificator from "./modules/notificator";
import back_common from "@/store/modules/back_common";
import all_configs from "@/store/modules/all_configs";
import version from "@/store/modules/version";
import manual_updater from "./modules/manual_updater";
import yametric_target from "./modules/yametric_target";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    preloader,
    notificator,
    manual_updater,
    yametric_target,
    back_common,
    all_configs,
    version
  }
});
